import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";


import { DatePipe, formatDate } from "@angular/common";
import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { first } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CommonServicesService } from "src/app/services/common-services.service";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import swal from "sweetalert2";
import { identifierModuleUrl, ThrowStmt } from "@angular/compiler";
import { AdminServiceService } from 'src/app/services/admin-service.service';
import { ActivatedRoute } from '@angular/router';
import { CommonRejectComponent } from 'src/app/common-reject/common-reject.component';
import { DlApproveComponent } from '../dl-approve/dl-approve.component';
import { AngularMultiSelect } from "angular2-multiselect-dropdown";
import { I } from "@angular/cdk/keycodes";


@Component({
  selector: 'app-edit-delivery-note',
  templateUrl: './edit-delivery-note.component.html',
  styleUrls: ['./edit-delivery-note.component.css'],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class EditDeliveryNoteComponent implements OnInit {
  emailPattern = /^([\w-]|(?<!\.)\.)+[a-zA-Z0-9]@[a-zA-Z0-9]([\w\-]+)((\.([a-zA-Z]){2,9})+)$/;
  expandedElement: PeriodicElement | null;
  @ViewChild('dropdownRef', { static: false }) dropdownRef: AngularMultiSelect;
  @ViewChild('myInput') myInputVariable: ElementRef;
  showOverlay: boolean;
  certEscolar: any = "";
  fileCount = 0;
  subQty: any = [];
  vendorList: any = [];
  catelist:any=[{
    name:"Goods receive Mvt 103",
    value:"001"
  },
  {
    name:"Goods receive Mvt 101",
    value:"002"
  }
];
  deliveryDetails: any = []
  pageIndex: any;
  plantType: any;
  vendorType: any;
  cate:any;
  pageSize: any;
  documents: any;
  show:boolean=true;
  lCategory:boolean=false;
  readonly:boolean=true;
  isReject:boolean = false;
  isCatogary: boolean;
  language:any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public _snackBar: MatSnackBar,
    private cService: CommonServicesService,
    private aService: AdminServiceService,
    private route: ActivatedRoute,
    public datepipe: DatePipe
  ) { }
  // @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
  //   swal.fire(`${this.language.swalcp}`);
  //   e.preventDefault();
  // }
  // @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
  //   swal.fire(`${this.language.swalcp}`);
  //   e.preventDefault();
  // }
  // @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
  //   e.preventDefault();
  // }
  deliveryDate: any;
  editDeliveryNotes: FormGroup = this.fb.group({
    // poNumber: ["", [Validators.required]],
    // plantType: ["", [Validators.required]],
    // vendorType: ["", [Validators.required]],
    billOfLading: ["", [Validators.required]],
    dlDate: ["", [Validators.required]],
    categoryType: ["", [Validators.required]],
    dlNum: ["", [Validators.required]],
    psDate: ["", [Validators.required]],
    hedText: ["", [Validators.required]],
    venName: ["", [Validators.required]],
    // prGroup: ["", [Validators.required]],
    postingDate: ["", [Validators.required]],
    totalQty: ["", [Validators.required]],
    header: [""],
    dlPerson: [""],
    dlNumber: ["", [Validators.required]],
    shipmentNo: [""],
    billOfLanding: [""],
    totalAmt: ["", [Validators.required]],
    category: ["", [Validators.required]],
    dlPersonEmail: ['', [Validators.email, Validators.pattern(this.emailPattern)]],
    delQtys: ["", [Validators.required]],

  });
  date: any;
  dropdownList = [];
  dropdownSettings = {};
  dropdownSettings4 = {};
  columnsToDisplay = [];
  // columnsToDisplay = ["poNumber", "poItem", "materialNo", "storageLocation", "category", "shortText", "poQty", "receivedQty","pendingQty", "unitPrice", "poAmount", "delQty", "totalAmt", "action"];
  poListDropdown: any = {};
  poList: any;
  poItems: any;
  selectedItems: any = [];
  preSelectedItem: any=[];
  totalAmt: any;
  deliveryQty: any;
  unitPrice: any;
  dataSource: any = [];
  sub: any;
  currentUser: any = [];
  mySpinner: boolean = false;
  routeFromD: any;
  userType: any;
  warHouseUser: any;
  deliveryNoteDetails: any = [];
  subdeliverynote:any=[];
  masterDeliveryNoteDetails: any = [];
  itemList = [];
  settings = {};
  pDisable :boolean = true;
  isDisabledUqr:boolean=false;
  async ngOnInit(): Promise<void> {
    // this.selectedItems = [
    //     { "poId": 2, "poNumber": "4100056139" }
    //   ];
    this.sub = this.route.params.subscribe((params) => {
      this.routeFromD = params["id"];
    })
    localStorage.removeItem("supportDocs");
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    if(lang == 'en'){
      this.language = {
        "head":"Update Delivery Note",
        "companyName":	"Company Name *",
        "plantName":	"Plant Name *",
        "PONumber":	"PO Number",
        "deliveryNoteDate" :	"Delivery Note Date",
        "deliveryNoteNumber" :	"Delivery Note Number",
        "postingDate" :	"Posting Date",
        "totalQuantity":	"Total Quantity",
        "totalAmount" :	"Total Amount",
        "headerText":	"Header Text",
        "deliveryPersonEmail":	"Delivery Person Email",
        "deliveryPersonName":	"Delivery Person Name",
        "attachments":"Attachments",
        "vendorCode":"Vendor Code *",
        "deliveryNoteDetails":	"Delivery Note Details",
        "actions":	"actions",
        "deliveringQuantity":	"Delivering Quantity",
        "POItem"	:"PO Item",
        "materialNo"	:"Material No",
        "shortText":	'Short Text',
        "POQTY":	"PO QTY",
        "unit":	"Unit",
        "deliveryDate"	:"Delivery Date",
        "receivedQTY"	:"Received QTY",
        "pendingQTY":	"Pending QTY",
        "unitPrice"	:"Unit Price",
        "itemAmount":	"Item Amount",
        "invoicedAmount":	"GR Amount",
        "category":"Category",
        "storageLocation":"Storage Location",
        "deliveringQty":"Delivering Qty",
        "subItemNo":"Sub Item No",
        "bom":"BOM",
        "submit":"Submit",
        "cancel":"Cancel",
        "comment":"Comment",
        "approve":"Approve",
        "reject":"Reject",
        "releaseStock":"Release Stock",
        "update":"Update",
        "POAmount":"PO Amount",
        "note":"Note",
        "noteLine1":"Please Select jpeg, png, pdf, doc, docx file Formats,",
        "noteLine2":"Max file size less than 5Mb, Max file quantity is 3.",
        "swalpo":"Purchase Order",
        "swalselect":"does not contain any selectable Items.",
        "swalLPQ":"Delivery qty should be less than Pending Qty",
        "swalgtz":"Delivery quantity should be greater than zero",
        "swaldngftpd":"Delivery note date should not be greater than posting date",
        "swaldn":"Please enter delivery note number",
        "swaltotqty":"Please enter total quantity",
        "swalamount":"Please enter total amount",
        "swalemail":"Enter a valid email address",
        "swalsdoc":"Please upload the supporting documents",
        "swalqty":"Please enter quantity",
        "swalc1":"Only 16 charecters are allowed",
        "swalc2":"Only 25 charecters are allowed",
        "swalc3":"Only 12 charecters are allowed",
        "swalformat":"Only JPG,PNG,PDF,DOC and DOCX files are allowed.",
        "swalsize":"File size cannot be greater than 5mb",
        "swaldiffile":"File is already uploaded, please upload different file",
        "swalmaxfile":"Maximum limit 3 files",
        "swaldec":"Three numbers only allowed after decimal point",
        "swalfdec":"Decimal point not allowed at first place",
        "swalneg":"Negative value are not allowed",
        "swalEmcom":"Comment can not be empty",
        "swaldndate":"Please enter delivery date",
        "swalcategory":"Please select category",
        "swalcp":"copy and paste not allowed"
      }
    }
    else if(lang == 'th'){
      this.language = {
        "head":"อัพเดทใบส่งของ",
        "companyName":	"ชื่อ บริษัท *",
        "plantName":	"ชื่อพืช *",
        "PONumber":	"เลขที่ใบสั่งซื้อ",
        "deliveryNoteDate" :	"วันที่บันทึกการจัดส่ง",
        "deliveryNoteNumber" :	"หมายเลขใบส่งสินค้า",
        "postingDate" :	"วันที่โพสต์",
        "totalQuantity":	"ปริมาณทั้งหมด",
        "totalAmount" :	"จำนวนเงินทั้งหมด",
        "headerText":	"ข้อความส่วนหัว",
        "deliveryPersonEmail":	"อีเมล์คนส่งของ",
        "deliveryPersonName":	"ชื่อผู้ส่งสินค้า",
        "attachments":"เอกสารแนบ",
        "vendorCode":"รหัสผู้ขาย *",
        "deliveryNoteDetails":	"รายละเอียดใบส่งสินค้า",
        "actions":	"การดำเนินการ",
        "deliveringQuantity":	"ส่งมอบจำนวน",
        "POItem"	:"รายการสั่งซื้อ",
        "materialNo"	:"วัสดุ ตัวเลข",
        "shortText":	'ข้อความสั้น',
        "POQTY":	"PO ปริมาณ",
        "unit":	"หน่วย",
        "deliveryDate"	:"วันที่จัดส่ง",
        "receivedQTY"	:"ได้รับ ปริมาณ",
        "pendingQTY":	"รอดำเนินการ ปริมาณ",
        "unitPrice"	:"ราคาต่อหน่วย",
        "itemAmount":	"จำนวนรายการ",
        "invoicedAmount":	"จำนวน GR",
        "category":"หมวดหมู่",
        "storageLocation":"สถานที่จัดเก็บ",
        "deliveringQty":"ปริมาณการส่งมอบ",
        "subItemNo":"รายการย่อยหมายเลข",
        "bom":"บอม",
        "submit":"ส่ง",
        "cancel":"ยกเลิก",
        "comment":"ความคิดเห็น",
        "approve":"อนุมัติ",
        "reject":"ปฏิเสธ",
        "releaseStock":"ปล่อยสต็อก",
        "update":"อัปเดต",
        "POAmount":"จำนวนเงินที่สั่งซื้อ",
        "note":"หมายเห",
        "noteLine1":"โปรดเลือกรูปแบบไฟล์ jpeg, png, pdf, doc, docx,",
        "noteLine2":"ขนาดไฟล์สูงสุดน้อยกว่า 5Mb ปริมาณไฟล์สูงสุดคือ 3.",
        "swalpo":"ใบสั่งซื้อ",
        "swalselect":"ไม่มีรายการที่เลือกได้",
        "swalLPQ":"ปริมาณการจัดส่งควรน้อยกว่าปริมาณที่รอดำเนินการ",
        "swalgtz":"ปริมาณการจัดส่งควรมากกว่าศูนย์",
        "swaldngftpd":"วันที่บันทึกการจัดส่งไม่ควรเกินวันที่โพสต์",
        "swaldn":"กรุณากรอกเลขที่ใบส่งของ",
        "swaltotqty":"กรุณาใส่จำนวนทั้งหมด",
        "swalamount":"กรุณาใส่จำนวนเงินทั้งหมด",
        "swalemail":"ใส่อีเมล์ที่ถูกต้อง",
        "swalsdoc":"กรุณาอัพโหลดเอกสารประกอบการ",
        "swalqty":"กรุณากรอกจำนวน",
        "swalc1":"อนุญาตให้ใช้อักขระได้เพียง 16 ตัวเท่านั้น",
        "swalc2":"อนุญาตให้ใช้อักขระได้เพียง 25 ตัวเท่านั้น",
        "swalc3":"อนุญาตให้ใช้อักขระได้เพียง 12 ตัวเท่านั้น",
        "swalformat":"อนุญาตเฉพาะไฟล์ JPG, PNG, PDF, DOC และ DOCX",
        "swalsize":"ขนาดไฟล์ต้องไม่เกิน 5Mb",
        "swaldiffile":"ไฟล์ถูกอัพโหลดแล้ว กรุณาอัพโหลดไฟล์อื่น",
        "swalmaxfile":"จำกัดสูงสุด 3 ไฟล์",
        "swaldec":"อนุญาตให้ใช้ตัวเลขสามตัวหลังจุดทศนิยมเท่านั้น",
        "swalfdec":"ไม่อนุญาตให้ใช้จุดทศนิยมในตอนแรก",
        "swalneg":"ค่าลบไม่ได้รับอนุญาต",
        "swalEmcom":"ความคิดเห็นไม่สามารถเว้นว่างได้",
        "swaldndate":"กรุณาระบุวันที่จัดส่ง",
        "swalcategory":"กรุณาเลือกหมวดหมู่",
        "swalcp":"ไม่อนุญาตให้คัดลอกและวาง"
      }
    }

    if (this.currentUser.roleID == 3 || this.currentUser.roleID == 7 ){
      // this.columnsToDisplay = ["poNumber", "poItem", "poQty", "receivedQty", "pendingQty", "unitPrice", "poAmount", "delQty", "totalAmt", "storageLocation", "materialNo", "category", "shortText", "action"]
      this.columnsToDisplay = ["delQty","totalAmt","storageLocation","poNumber", "poItem","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ]
      }else{
        // this.columnsToDisplay = ["poNumber", "poItem", "poQty", "receivedQty", "pendingQty", "unitPrice", "poAmount", "delQty", "totalAmt", "materialNo", "shortText", "action"]
        this.columnsToDisplay = ["delQty","totalAmt","poNumber", "poItem","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ]
      }
    this.deliveryDate = formatDate(new Date(), "dd/MM/yyyy", "en-US");
    this.date = new Date();
    this.userType = localStorage.getItem("user");
    this.warHouseUser = this.routeFromD;
    // this.selectedItems = [{poId: '1',poNumber: '4100056138'}];
    this.dropdownSettings = {
      singleSelection: false,
      idField: "poId",
      textField: "poNumber",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 1,
      //allowRemoteDataSearch : true,
      allowSearchFilter: true,
    };
    this.dropdownSettings4 = {
      singleSelection: false,
      idField: "companyCode",
      textField: "nameEn",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 1,
      //allowRemoteDataSearch : true,
      allowSearchFilter: true,
    };
    this.settings = {
      singleSelection: false,
      text: "Select PO number",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      classes: "myclass custom-class",
      labelKey: 'itemName',
      enableSearchFilter: false,
      badgeShowLimit: 1
    };
    this.editDeliveryNotes = this.fb.group({
      // poNumber: ["", [Validators.required]],
      // plantType: ["SCCC Head office", [Validators.required]],
      // vendorType: ["", [Validators.required]],
      billOfLading: ["", [Validators.required]],
      dlDate: ["", [Validators.required]],
      dlNum: ["", [Validators.required]],
      categoryType: ["", [Validators.required]],
      psDate: ["", [Validators.required]],
      hedText: ["", [Validators.required]],
      venName: ["", [Validators.required]],
      // prGroup: ["", [Validators.required]],
      postingDate: ["", [Validators.required]],
      totalQty: ["", [Validators.required]],
      header: [""],
      dlPerson: [""],
      dlNumber: ["", [Validators.required]],
      shipmentNo: [""],
      billOfLanding: [""],
      totalAmt: ["", [Validators.required]],
      category: ["", [Validators.required]],
      dlPersonEmail: ['', [Validators.email, Validators.pattern(this.emailPattern)]],
      delQtys: ["", [Validators.required]],
      

    });
    
    this.loadSupportingDocs();
    // await this.getDelivaryNotesByRefNo(this.routeFromD);
    await this.getDelivaryNotesById(this.routeFromD);

  }
  async getDelivaryNotesById(dnId){
    this.mySpinner = true;
    (await this.cService.getDeliveryNoteById(dnId)).pipe(first()).subscribe(async res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        this.delStatus = res.response.dnDetails.deliveryStatus;
        this.delStatusId= res.response.dnDetails.statusId
        this.companyMaster = res.response.companyCodesList;
        this.vendorList = res.response.vendorList;
        this.dropdownList = res.response.poList;
        this.plantMaster = res.response.plantList;
        if(res.response.dnDetails.statusId == '4' ){
          this.isCatogary = true;
        }else{
          this.isCatogary = false;
        }
       
        this.companyCode = res.response.companyCodesList[0].companyCode;
        // if(this.)
        this.mySpinner = false;
        // this.changeCom(this.companyCode);
        // await this.getPlantMaster(res.response.companyCode);
        if (this.currentUser.roleID == 3 || this.currentUser.roleID == 7) {
          if(this.delStatus == 'Rejected'){
            this.columnsToDisplay = ["action","delQty","storageLocation","poNumber", "poItem","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ,"totalAmt"]
          }
          
          await this.storageLocationList(this.plantMaster[0].plantCode);
          
        }else{
          if(this.delStatus == 'Rejected'){
            this.columnsToDisplay = ["action","delQty","poNumber", "poItem","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ,"totalAmt"]
          }
        }
        if(res.response.dnDetails.statusId == 3){
          this.pDisable = false;
        }
        if(this.currentUser.roleID == 3 || this.currentUser.roleID == 7){
          this.pDisable = false;
        }
        this.plantId = this.plantMaster[0].plantCode;
        this.setDnDetails(res.response.dnDetails);
        
        this.deliveryDetailsByPO = res.response.dnDetails;
        let len:any
        len = this.deliveryDetailsByPO.deliveryNoteStatus.length-1;
        if(len > 0){
          this.comment = this.deliveryDetailsByPO.deliveryNoteStatus[len].deliveryStatusComments;
        }
        // setTimeout(async () => {
        //   await this.changePlantSet(res.response);
        //   // this.setDeliveryNoteData(res.response);  
        // }, 500);
      }
      })
  }
  selectedValues: any = [];
  onItemSelect(item: any) {
    this.show=true;
    this.selectedValues.push(item.poId);
    this.getDeliveryNoteDetails(item);
  }

  onSelectAll(item: any) {
    this.show=true;
    this.selectedValues = [];

    for (let i = 0; i <= item.length - 1; i++) {
      this.selectedValues.push(item[i].poId);
      this.selectedItems.push(item[i]);
    }
    this.getDeliveryNoteDetails(item);
  }

  onItemDeSelect(item: any) {
    this.show=false;
    const index = this.selectedValues.indexOf(item);
    let i = this.selectedValues.findIndex(function (ritem) {
      return ritem.poNumber == item.poNumber;
    });
    this.selectedValues.splice(item.poId, 1);
    this.getDeliveryNoteDetails(item);
  }
  onItemDeSelectAll(item: any) {
    this.show=false;
    // const index = this.selectedValues.indexOf(item);
    this.selectedItems = [];
    this.selectedValues = [];
   
    this.getDeliveryNoteDetails(item);
  }
  displayData:boolean=true;
  onItemDeSelectCom(item: any) {
    this.showP = false;

  }

  listValue: any;
  datacache: any = [];
  loadSupportingDocs() {
    let data = localStorage.getItem("supportDocs");
    if (data == undefined) {
      localStorage.setItem("supportDocs", JSON.stringify([]));
      this.listValue = [];
    } else {
      this.listValue = JSON.parse(localStorage.getItem("supportDocs"));
    }
  }
  plantMaster: any = [];
  categoryMaster = [
    // { categoryId: "001", categoryName: "Blocked Stock (MIGO #001)" },
    // { categoryId: "002", categoryName: "GR PO Creation (MIGO #002)" },
    { categoryId: "001", categoryName: "Goods receive Mvt 101" },
    { categoryId: "002", categoryName: "Goods receive Mvt 103" },
    { categoryId: "001-1", categoryName: "Release GR Blocked Stock (MIGO #001-1)" },
    { categoryId: "005", categoryName: "Sub Contract GR (MIGO #005)" },
    { categoryId: "008", categoryName: "GR of Scheduling Agreement (MIGO #008)" },
  ];
  poLists: any = [];
  plantId: any;
  async getPlantMaster(com:any) {
    this.mySpinner = true;
    (await this.cService.getPlantMaster(com)).pipe(first()).subscribe(async (data) => {
      if (data != null) {
        if (data.responseStatus.toUpperCase() == "SUCCESS") {
          this.plantMaster = data.response;
          this.mySpinner = false;
        } else {
          if(data.responseCode == '401'){
            (await this.cService.getPlantMaster(com)).pipe(first()).subscribe((data) => {
              if (data != null) {
                if (data.responseStatus.toUpperCase() == "SUCCESS") {
                  this.plantMaster = data.response;
                  this.mySpinner = false;
                } else {
                  this.mySpinner = false;
                  swal.fire(data.responseMessage, "", "error");
                }
              }
            },
              error => {
                this.mySpinner = false;
              }
            );
          }else{
            this.mySpinner = false;
            swal.fire(data.responseMessage, "", "error");
          }
          
        }
      }
    },
      error => {
        this.mySpinner = false;
      }
    );
  }
  companyMaster:any=[];
  async getCompanyMaster(){
    this.mySpinner = true;
    (await this.cService.getCompanyMaster(this.currentUser.usertype)).pipe(first()).subscribe(async (data) => {
       
        if (data != null) {
          if (data.responseStatus.toUpperCase() == "SUCCESS") {
            this.companyMaster = data.response;
            this.mySpinner = false;
          } else {
            // this.mySpinner = false;
            // 
           
            if(data.responseCode == '401'){
              (await this.cService.getCompanyMaster(this.currentUser.usertype)).pipe(first()).subscribe((data) => {
       
                if (data != null) {
                  if (data.responseStatus.toUpperCase() == "SUCCESS") {
                    this.companyMaster = data.response;        
                    this.mySpinner = false;
                  } else {
                    this.mySpinner = false;
                    swal.fire(data.responseMessage, "", "error");
                  }
                }
              },
              () => {
                this.showOverlay = false;
              }
            );
            }else{
              swal.fire(data.responseMessage, "", "error");
            }
          }
        }
      },
      () => {
        this.showOverlay = false;
      }
    );
  }
  
  async getCompanyMasterWare(vendorId:any){
    this.mySpinner = true;
    (await this.cService.getCompanyMaster(vendorId)).pipe(first()).subscribe(async (data) => {
       
        if (data != null) {
          if (data.responseStatus.toUpperCase() == "SUCCESS") {
            this.companyMaster = data.response;
            this.mySpinner = false;
          } else {
            // this.mySpinner = false;
            // swal.fire(data.responseMessage, "", "error");
           
            if(data.responseCode == '401'){
              (await this.cService.getCompanyMaster(vendorId)).pipe(first()).subscribe((data) => {
       
                if (data != null) {
                  if (data.responseStatus.toUpperCase() == "SUCCESS") {
                    this.companyMaster = data.response;
                    this.mySpinner = false;
                  } else {
                    this.mySpinner = false;
                    swal.fire(data.responseMessage, "", "error");
                  }
                }
              },
              () => {
                this.showOverlay = false;
              }
            );
            }else{
              this.mySpinner = false;
              swal.fire(data.responseMessage, "", "error");
            }
          }
        }
      },
      () => {
        this.showOverlay = false;
      }
    );
  }
  
  showP:boolean=false;
  companyCode:any;
  changeCompany(event: any) {
    this.plantId = "";
    this.showP = true;
    this.companyCode = event.companyCode;
    this.getPlantMaster(this.companyCode);
    
  }
  // changeCom(com: any) {
  //   this.companyCode = "";
  //   // let plant = event.source.value;
  //   this.companyCode = com;
  //   setTimeout(() => {
  //     this.getPlantMaster(this.companyCode);  
  //   }, 200);
    

  // }
  changePlant(event: any) {
    this.plantId = "";
    let plant = event.source.value;
    this.plantId = plant;
    this.plantType = plant;
    this.getPONumbers();
    if (this.currentUser.roleID == 3 || this.currentUser.roleID == 7) {
      this.storageLocationList(plant);
    }
  }

  async changePlantSet(plantCode: any) {
    this.plantId = "";
    // let plant = event.source.value;
    this.plantId = plantCode.plantCode;
    setTimeout(async () => {
      await this.getPONumbers1(plantCode);  
    }, 200);
    

  }
  onItemSet(item: any) {
    this.selectedItems.push(item);
  }
  changeVendor(event: any) {
    this.vendorType = event.source.value;
    let vendorCodex;
    for (let i of this.vendorList){
      if(event.id == i.id){
        vendorCodex = i.vendorCode
      }
    }

  }

  async getVendorCodes() {
    this.mySpinner = true;
    (await this.aService.getVendorsCode()).pipe(first()).subscribe(async res => {
      this.mySpinner = false;
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        this.vendorList = res.response;
       
      }else{
        if(res.responseCode == '401'){
          (await this.aService.getVendorsCode()).pipe(first()).subscribe(res => {
            this.mySpinner = false;
            if (res.responseStatus.toUpperCase() == "SUCCESS") {
              this.vendorList = res.response;
             
            }else{
             swal.fire(res.responseMessage, '', 'error');
            }
          }, error => {
            this.mySpinner = false;
          })
        }else{
          this.mySpinner = false;
          swal.fire(res.responseMessage, '', 'error');
         }
       
      }
    }, error => {
      this.mySpinner = false;
    })

  }

  raiseInvoice() {
    if (this.routeFromD == 'dNotes') {
      this.router.navigate(["invoice-list/invoice-details", "dNotes"])
    } else if (this.routeFromD == 'dNotes1') {
      this.router.navigate(["invoice-list/invoice-details", "dNotes1"])
    } else if (this.routeFromD == 'po') {
      this.router.navigate(["invoice-list/invoice-details", "po"])
    }

  }

  openDialog(): void {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    let data:any;
    if(lang == 'en'){
      data = 'delivery note'
    }else{
      data = 'บันทึกการส่งมอบ'
    }
    const dialogRef = this.dialog.open(DlApproveComponent, {
      width: '500px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.data == "Approved") {
        this.onSubmit();
        // swal.fire("Delivery note is approved");
        // if (result.data == "Approved") {
        //   swal.fire("Delivery note is approved");
        //   if (this.routeFromD == 'warDl') {
        //     this.router.navigate(["dashboard"])
        //   } else {
        //     this.router.navigate(["delivery-notes"])
        //   }

        // } else {
        //   // swal.fire("Delivery note is approved");
        // }

        // swal.fire("User creation successful!");
      }

    })
  }
  changedInput:boolean= false;
  onInputchanges(event:any){
    this.changedInput = true;
  }

  onReject(): void {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    let data:any;
    if(lang == 'en'){
      data = 'delivery note'
    }else{
      data = 'บันทึกการส่งมอบ'
    }
    const dialogRef = this.dialog.open(CommonRejectComponent, {
      width: '500px',
      data: data
    });
    dialogRef.afterClosed().subscribe(async result => {
      let rejectData = {
        deliveryNoteId: this.routeFromD,
        deliveryNoteStatus: 3,
        deliveryStatusComments: result.comment,
        toEmailIds:result.newemail[0],
        deliveredItemsReturn: "F"
      }
      if(result.comment == null){
        swal.fire(`${this.language.swalEmcom}`, "", "warning");
      }else{
      if (result.data == "Rejected") {
        ;(await this.cService.updateDeliveryStatus(rejectData)).pipe(first()).subscribe((data) => {
          if (data.responseStatus.toUpperCase() == "SUCCESS") {
            // swal.fire(data.responseMessage, "", "success");

            localStorage.setItem("searchData", "");
            localStorage.setItem("tableData", "");

            swal.fire({
              icon: 'success',
              text: data.responseMessage
            })
            swal.fire("VPDN# "+ data.response.deliveryNoteRefNumVp.substring(data.response.deliveryNoteRefNumVp.indexOf('_')+1), data.responseMessage, 'success');
            // swal.fire("Delivery note is Rejected");
            // if (this.routeFromD == 'warDl') {
            //   this.router.navigate(["dashboard"])
            // } else {
              
            //   this.router.navigate(["delivery-notes"])
            // }
            this.router.navigate(["delivery-notes"])
          }
          else {
            swal.fire(data.responseMessage , "", "error");
          }

        });
      } else {
        return null
      }
      }
    })
  }
  deliveryStatus: any;
  categoryBlocked: boolean = false;
  blockedHeaders: boolean = true;
  statusId:any;
  dataSubSource:any=[];
  categoryDi:boolean=false;
  subCateg:boolean=false;
  categoryShow:any;
  categoryValue:any;
  async getPONumbers1(data: any) {
    this.itemList = [];
    let vendorId:any;
    if(this.currentUser.roleID == 3 || this.currentUser.roleID == 7){
      vendorId = data.vendorId;
    }else{
      vendorId = this.currentUser.vendorId
    }
    this.mySpinner=true;//, this.companyCode
    (await this.cService.getPONumbers(this.plantId, "G", vendorId, this.companyCode)).pipe(first()).subscribe((res) => {
      if (res != null) {
        if (res.responseStatus.toUpperCase() == "SUCCESS") {
          let reqDat:any = [];
          // this.dropdownList = res.response;
          let dropdownValues = [];
          for (let i = 0; i <= res.response.length - 1; i++) {
            let obj = {
              poId: res.response[i].poId.toString(),
              poNumber: res.response[i].poNumber,
              isDisabled: false
            };
            dropdownValues.push(obj);
            // this.dropdownList = this.dropdownList.concat({
            //   poId: res.response[i].poId.toString(),
            //   poNumber: res.response[i].poNumber
            // });
          }
          this.dropdownList = dropdownValues;
          this.plantType = data.plantCode;
          this.masterDeliveryNoteDetails = data.deliveryNoteItemDetails;
          this.deliveryNoteDetails = data.deliveryNoteItemDetails;
          let h=0;
          for(let i of data.deliveryNoteItemDetails){
            let objx=[];
            if(i.subContractSubItems.length > 0){
              if(i.subContractSubItems[0].id != undefined){
                objx = [
                  {
                    "id": i.subContractSubItems[0].id,
                    "rowVersion": i.subContractSubItems[0].rowVersion,
                    "subItemMaterial": i.subContractSubItems[0].subItemMaterial,
                    "subItemNo": i.subContractSubItems[0].subItemNo,
                    "subItemReceivedQty": parseFloat(i.warehouseReceivedQuantity).toFixed(3),
                    "subItemShortText": i.subContractSubItems[0].subItemShortText
                  }
                 ]
              }
            }
            let obj =
            {
             
             "grQuantity": i.grQuantity,
             "warehouseReceivedQuantity": parseFloat(i.warehouseReceivedQuantity).toLocaleString('en',{minimumFractionDigits:3}),
             "storageLocation": i.storageLocation,
             "poId": i.poId,
             "id":i.id,
             "podId": i.podId,
             "poNumber": i.poNumber,
             "poItem": i.poItem,
             "shortText": i.shortText,
             "material": i.material,
             "materialGroup": i.materialGroup,
             "scheduledQuantity": i.scheduledQuantity,
             "purchaseOrderGrQuantity": i.purchaseOrderGrQuantity,
             "outStandingQuantity": i.outStandingQuantity,
             "outStandingUnit":i.outStandingUnit,
             "poDeliveryDate":i.poDeliveryDate,
             "priceUnit": i.priceUnit,
             "unit":i.outStandingUnit,
             "netPrice": i.netPrice,
             "grossValue": i.grossValue,
             "amount": i.amount,
             "totalAmt": parseFloat(i.warehouseReceivedQuantity) * parseFloat(i.netPrice) ,
             "category": i.category,
             "totalPenQty": i.outStandingQuantity,
             "subContractSubItems": objx
            }
            reqDat.push(obj);
          }
          
         this.deliveryNoteDetails = reqDat;         
          this.deliveryStatus = data.deliveryStatus;
          let selected = [];
          
          for (let i of this.deliveryNoteDetails) {          
            this.subdeliverynote = i.subContractSubItems;
            // i.storageLocation = "1000";
            let obj = {
              poId: i.poId.toString(),
              poNumber: i.poNumber,
              isDisabled: true
            };
            if(data.statusId == '1'){
              this.statusId = data.statusId;
            }else{
              this.statusId = data.statusId;
            }
            selected.push(obj);
            this.categoryShow = i.category;
            if(i.category == '001-1'){
              i.category = "";
              this.categoryValue = "";
            }
            if(i.category == "005"){
              i.category = "Sub Contract GR (MIGO #005)";
              this.categoryValue = "Sub Contract GR (MIGO #005)";
              this.subCateg = true;
              this.categoryDi = false;
            }else if(i.category == "008"){
              this.subCateg = false;
              i.category = "GR of Scheduling Agreement (MIGO #008)";
              this.categoryValue = "GR of Scheduling Agreement (MIGO #008)";
              this.categoryDi = false;
            }else{
              this.subCateg = false;
              this.categoryDi = true;
            }
          } 
          for(let i of data.deliveryNoteItemDetails){
            if(i.itemCategory != null){
              if(i.itemCategory == "L"){
                this.subCateg = true;
                let obj = {
                  "materialNo":i.subContractSubItems.subItemMaterial,
                  "shortText":i.subContractSubItems.subItemShortText,
                  "subItemNo":i.subContractSubItems.subItemNo,
                  "quantity":i.subContractSubItems.subItemReceivedQty
                };
                this.dataSubSource.push(obj);
              }else{
                this.subCateg = false;
              }
            }
          }
          if(this.subCateg == true){
            if(this.currentUser.roleID == 3 || this.currentUser.roleID == 7){
              //this.columnsToDisplay = ["action","delQty","storageLocation","poNumber", "poItem","plantName","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ,"totalAmt"]
              this.columnsToDisplay = ["action","delQty","storageLocation","poNumber", "poItem","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ,"totalAmt"]
            }else{
              //this.columnsToDisplay = ["action","delQty","poNumber", "poItem","plantName","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ,"totalAmt"]
              this.columnsToDisplay = ["action","delQty","poNumber", "poItem","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ,"totalAmt"]
            }
           
          }
          this.selectedItems = selected;
          this.preSelectedItem = this.selectedItems;
          if (!this.categoryBlocked) {
            this.editDeliveryNotes = this.fb.group({
              // plantType: new FormControl({ value: data.plantCode, disabled: false }),
              // vendorType: new FormControl({ value: data.vendorId + '-' + data.sapVendorCode, disabled: false }),
              dlDate: new FormControl({ value: data.deliveryNoteDate, disabled: false }),
              dlNum: new FormControl({ value: data.deliveryNoteNumberByVendor, disabled: false }),
              categoryType: new FormControl({ value: "", disabled: false }),
              psDate: new FormControl({ value: this.deliveryDate, disabled: false }),
              hedText: new FormControl({ value: data.headerText, disabled: false }),
              venName: new FormControl({ value: data.deliveryToPersonName, disabled: false }),
              // prGroup: new FormControl({ value:"", disabled:false }),
              postingDate: new FormControl({ value: this.deliveryDate, disabled: false }),
              totalQty: new FormControl({ value: data.quantity.toLocaleString('en', {
                minimumFractionDigits: 3
            }), disabled: false }),
              header: new FormControl({ value: data.headerText, disabled: false }),
              dlPerson: new FormControl({ value: data.deliveryToPersonName, disabled: false }),
              dlNumber: new FormControl({ value: data.deliveryToPersonEmail, disabled: false }),
              shipmentNo: new FormControl({ value: data.shipmentNumber, disabled: false }),
              billOfLading: new FormControl({ value: data.billOfLading, disabled: false }),
              totalAmt: new FormControl({ value: data.totalAmount.toLocaleString('en', {
                minimumFractionDigits: 2
            }), disabled: false }),
              category: new FormControl({ value: data.category, disabled: false }),
              dlPersonEmail: new FormControl({ value: data.deliveryToPersonEmail, disabled: false }),
              delQtys: new FormControl({ value: "", disabled: false }),
              // poNumber: new FormControl(this.selectedItems, Validators.required),

            });
          } else {
            this.editDeliveryNotes = this.fb.group({
              // plantType: new FormControl({ value: data.plantCode, disabled: false }),
              // vendorType: new FormControl({ value: data.vendorId + '-' + data.sapVendorCode, disabled: false }),
              dlDate: new FormControl({ value: data.deliveryNoteDate, disabled: true }),
              dlNum: new FormControl({ value: data.deliveryNoteNumberByVendor, disabled: true }),
              categoryType: new FormControl({ value: "", disabled: true }),
              psDate: new FormControl({ value: this.date, disabled: true }),
              hedText: new FormControl({ value: data.headerText, disabled: true }),
              venName: new FormControl({ value: data.deliveryToPersonName, disabled: true }),
              // prGroup: new FormControl({ value:"", disabled:true }),
              postingDate: new FormControl({ value: this.deliveryDate, disabled: true }),
              totalQty: new FormControl({ value: data.quantity.toLocaleString('en', {
                minimumFractionDigits: 2
            }), disabled: true }),
              header: new FormControl({ value: data.headerText, disabled: true }),
              dlPerson: new FormControl({ value: data.deliveryToPersonName, disabled: true }),
              dlNumber: new FormControl({ value: data.deliveryToPersonEmail, disabled: true }),
              shipmentNo: new FormControl({ value: data.shipmentNumber, disabled: true }),
              billOfLading: new FormControl({ value: data.billOfLading, disabled: true }),
              totalAmt: new FormControl({ value: data.totalAmount.toLocaleString('en', {
                minimumFractionDigits: 3
            }), disabled: false }),
              category: new FormControl({ value: data.category, disabled: true }),
              dlPersonEmail: new FormControl({ value: data.deliveryToPersonEmail, disabled: true }),
              delQtys: new FormControl({ value: "", disabled: true }),
              // poNumber: new FormControl(this.selectedItems, Validators.required),

            });
          }
          // this.editDeliveryNotes.controls.poNumber.setValue(this.selectedItems);

          // this.editDeliveryNotes.
          this.editDeliveryNotes.controls.psDate.setValue(this.datepipe.transform(this.date,'yyyy-MM-dd'),);
          this.editDeliveryNotes.controls.shipmentNo.setValue(data.shipmentNumber);
          // this.editDeliveryNotes.controls.category.patchValue(data.deliveryNoteItemDetails[0].category);
          //this.companyCode = data.companyCode;
          this.documents = data.deliveryNoteSupportDocs;
          this.vendorType = data.vendorId + '-' + data.sapVendorCode;
          this.cate = data.deliveryNoteItemDetails[0].category;
          this.mySpinner = false;
        } else {
          this.mySpinner = false;
          swal.fire(data.responseMessage, "", "error");
        }
      }
    },
      error => {
        this.mySpinner = false;
      }
    );
  }

  setDnDetails(data:any){
    this.plantType = data.plantCode;
    this.masterDeliveryNoteDetails = data.deliveryNoteItemDetails;
    this.deliveryNoteDetails = data.deliveryNoteItemDetails;
    let h=0;
    let reqDat:any=[];
    for(let i of data.deliveryNoteItemDetails){
      let objx=[];
      if(i.subContractSubItems.length > 0){
        if(i.subContractSubItems[0].id != undefined){
          objx = [
            {
              "id": i.subContractSubItems[0].id,
              "rowVersion": i.subContractSubItems[0].rowVersion,
              "subItemMaterial": i.subContractSubItems[0].subItemMaterial,
              "subItemNo": i.subContractSubItems[0].subItemNo,
              "subItemReceivedQty": parseFloat(i.warehouseReceivedQuantity).toFixed(3),
              "subItemShortText": i.subContractSubItems[0].subItemShortText
            }
           ]
        }
      }
      let obj =
      {
       
       "grQuantity": i.grQuantity,
       "warehouseReceivedQuantity": parseFloat(i.warehouseReceivedQuantity).toLocaleString('en',{minimumFractionDigits:3}),
       "storageLocation": i.storageLocation,
       "poId": i.poId,
       "id":i.id,
       "podId": i.podId,
       "poNumber": i.poNumber,
       "poItem": i.poItem,
       "shortText": i.shortText,
       "material": i.material,
       "materialGroup": i.materialGroup,
       "scheduledQuantity": i.scheduledQuantity,
       "purchaseOrderGrQuantity": i.purchaseOrderGrQuantity,
       "outStandingQuantity": i.outStandingQuantity,
       "outStandingUnit":i.outStandingUnit,
       "poDeliveryDate":i.poDeliveryDate,
       "priceUnit": i.priceUnit,
       "unit":i.outStandingUnit,
       "netPrice": i.netPrice,
       "grossValue": i.grossValue,
       "amount": i.amount,
       "totalAmt": parseFloat(i.warehouseReceivedQuantity) * parseFloat(i.netPrice) ,
       "category": i.category,
       "totalPenQty": i.outStandingQuantity,
       "WRQty":parseFloat(i.warehouseReceivedQuantity).toLocaleString('en',{minimumFractionDigits:3}),
       "subContractSubItems": objx
      }
      reqDat.push(obj);
    }
    
   this.deliveryNoteDetails = reqDat;   
    this.deliveryStatus = data.deliveryStatus;
    let selected = [];
    
    for (let i of this.deliveryNoteDetails) {    
      this.subdeliverynote = i.subContractSubItems;
      // i.storageLocation = "1000";
      let obj = {
        poId: i.poId.toString(),
        poNumber: i.poNumber,
        isDisabled: true
      };
      if(data.statusId == '1'){
        this.statusId = data.statusId;
      }else{
        this.statusId = data.statusId;
      }
      selected.push(obj);
      this.categoryShow = i.category;
      if(i.category == '001-1'){
        i.category = "";
        this.categoryValue = "";
      }
      if(i.category == "005"){
        i.category = "Sub Contract GR (MIGO #005)";
        this.categoryValue = "Sub Contract GR (MIGO #005)";
        this.subCateg = true;
        this.categoryDi = false;
      }else if(i.category == "008"){
        this.subCateg = false;
        i.category = "GR of Scheduling Agreement (MIGO #008)";
        this.categoryValue = "GR of Scheduling Agreement (MIGO #008)";
        this.categoryDi = false;
      }else{
        this.subCateg = false;
        this.categoryDi = true;
      }
    } 
    for(let i of data.deliveryNoteItemDetails){
      if(i.itemCategory != null){
        if(i.itemCategory == "L"){
          this.subCateg = true;
          let obj = {
            "materialNo":i.subContractSubItems.subItemMaterial,
            "shortText":i.subContractSubItems.subItemShortText,
            "subItemNo":i.subContractSubItems.subItemNo,
            "quantity":i.subContractSubItems.subItemReceivedQty
          };
          this.dataSubSource.push(obj);
        }else{
          this.subCateg = false;
        }
      }      
    }
    if(this.subCateg == true){
      if(this.currentUser.roleID == 3 || this.currentUser.roleID == 7){
        //this.columnsToDisplay = ["action","delQty","storageLocation","poNumber", "poItem","plantName","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ,"totalAmt"]
        this.columnsToDisplay = ["action","delQty","storageLocation","poNumber", "poItem","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ,"totalAmt"]
      }else{
        //this.columnsToDisplay = ["action","delQty","poNumber", "poItem","plantName","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ,"totalAmt"]
        this.columnsToDisplay = ["action","delQty","poNumber", "poItem","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ,"totalAmt"]
      }
     
    }
    this.selectedItems = selected;
    this.preSelectedItem = this.selectedItems;
    // console(data.category)
    if (!this.categoryBlocked) {
      this.editDeliveryNotes = this.fb.group({
        // plantType: new FormControl({ value: data.plantCode, disabled: false }),
        // vendorType: new FormControl({ value: data.vendorId + '-' + data.sapVendorCode, disabled: false }),
        dlDate: new FormControl({ value: data.deliveryNoteDate, disabled: false }),
        dlNum: new FormControl({ value: data.deliveryNoteNumberByVendor, disabled: false }),
        categoryType: new FormControl({ value: "", disabled: false }),
        psDate: new FormControl({ value: this.deliveryDate, disabled: false }),
        hedText: new FormControl({ value: data.headerText, disabled: false }),
        venName: new FormControl({ value: data.deliveryToPersonName, disabled: false }),
        // prGroup: new FormControl({ value:"", disabled:false }),
        postingDate: new FormControl({ value: this.deliveryDate, disabled: false }),
        totalQty: new FormControl({ value: data.quantity.toLocaleString('en', {
          minimumFractionDigits: 3
      }), disabled: false }),
        header: new FormControl({ value: data.headerText, disabled: false }),
        dlPerson: new FormControl({ value: data.deliveryToPersonName, disabled: false }),
        dlNumber: new FormControl({ value: data.deliveryToPersonEmail, disabled: false }),
        shipmentNo: new FormControl({ value: data.shipmentNumber, disabled: false }),
        billOfLading: new FormControl({ value: data.billOfLading, disabled: false }),
        totalAmt: new FormControl({ value: data.totalAmount.toLocaleString('en', {
          minimumFractionDigits: 2
      }), disabled: false }),
        category: new FormControl({ value: data.category, disabled: false }),
        dlPersonEmail: new FormControl({ value: data.deliveryToPersonEmail, disabled: false }),
        delQtys: new FormControl({ value: "", disabled: false }),
        // poNumber: new FormControl(this.selectedItems, Validators.required),

      });
    } else {
      this.editDeliveryNotes = this.fb.group({
        // plantType: new FormControl({ value: data.plantCode, disabled: false }),
        // vendorType: new FormControl({ value: data.vendorId + '-' + data.sapVendorCode, disabled: false }),
        dlDate: new FormControl({ value: data.deliveryNoteDate, disabled: true }),
        dlNum: new FormControl({ value: data.deliveryNoteNumberByVendor, disabled: true }),
        categoryType: new FormControl({ value: "", disabled: true }),
        psDate: new FormControl({ value: this.date, disabled: true }),
        hedText: new FormControl({ value: data.headerText, disabled: true }),
        venName: new FormControl({ value: data.deliveryToPersonName, disabled: true }),
        // prGroup: new FormControl({ value:"", disabled:true }),
        postingDate: new FormControl({ value: this.deliveryDate, disabled: true }),
        totalQty: new FormControl({ value: data.quantity.toLocaleString('en', {
          minimumFractionDigits: 2
      }), disabled: true }),
        header: new FormControl({ value: data.headerText, disabled: true }),
        dlPerson: new FormControl({ value: data.deliveryToPersonName, disabled: true }),
        dlNumber: new FormControl({ value: data.deliveryToPersonEmail, disabled: true }),
        shipmentNo: new FormControl({ value: data.shipmentNumber, disabled: true }),
        billOfLading: new FormControl({ value: data.billOfLading, disabled: true }),
        totalAmt: new FormControl({ value: data.totalAmount.toLocaleString('en', {
          minimumFractionDigits: 3
      }), disabled: false }),
        category: new FormControl({ value: data.category, disabled: true }),
        dlPersonEmail: new FormControl({ value: data.deliveryToPersonEmail, disabled: true }),
        delQtys: new FormControl({ value: "", disabled: true }),
        // poNumber: new FormControl(this.selectedItems, Validators.required),

      });
    }
    // this.editDeliveryNotes.controls.poNumber.setValue(this.selectedItems);

    // this.editDeliveryNotes.
    this.editDeliveryNotes.controls.psDate.setValue(this.datepipe.transform(this.date,'yyyy-MM-dd'),);
    this.editDeliveryNotes.controls.shipmentNo.setValue(data.shipmentNumber);
    // this.editDeliveryNotes.controls.category.patchValue(data.deliveryNoteItemDetails[0].category);
    //this.companyCode = data.companyCode;
    this.documents = data.deliveryNoteSupportDocs;
    this.vendorType = data.vendorId + '-' + data.sapVendorCode;
    this.cate = data.deliveryNoteItemDetails[0].category;

    this.mySpinner = false;
  
  }

  onSubQty(event:any,element:any){
    this.onDelQtyChangeSub(event,element)  ;
  }

  onDelQtyChangeSub(event: any, element: any) {
    let etv = 0;
    let totalQty = 0;
    let totalAmt = 0;
    etv = event.target.value.replace(/,/g, "");
    if (parseFloat(element.outStandingQuantity) < etv) {
      swal.fire(`${this.language.swalLPQ}`, "" ,"warning");
      element.warehouseReceivedQuantity = 0;//Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      event.target.value = 0;//Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      element.totalAmt = 0;
      // element.warehouseReceivedQuantity = (0).toLocaleString('en',{minimumFractionDigits:3}),
      element.deliveryQty = (0).toFixed(3);
      // this.addDeliveryNotes.controls.totalQty.setValue(0);
      // this.addDeliveryNotes.controls.totalAmt.setValue(0);
      let totalQty = 0;
      let totalAmt = 0;
      for (let i of this.deliveryNoteDetails){
          if(i.warehouseReceivedQuantity != undefined){
            let x = i.warehouseReceivedQuantity;//.replace(/,/g, "");
            if(i.totalAmt != undefined){
              totalQty = totalQty + Number(x);
              totalAmt = totalAmt + i.totalAmt;
            }else{
              totalQty = totalQty + Number(x);
              totalAmt = totalAmt + i.amount;
            }
          }
      } 
      this.editDeliveryNotes.controls.totalQty.setValue(totalQty.toLocaleString('en',{minimumFractionDigits:3}));
      this.editDeliveryNotes.controls.totalAmt.setValue(totalAmt.toLocaleString('en',{minimumFractionDigits:2}));
      element.warehouseReceivedQuantity = Number(event.target.value).toLocaleString('en',{minimumFractionDigits:3});
      if(element.subContractSubItems.length > 0){
        element.subContractSubItems[0].subItemReceivedQty = Number(event.target.value).toLocaleString('en',{minimumFractionDigits:3});
      }
      

    } else {
      // let etv = event.target.value.replace(/,/g, "");
      element.totalAmt = etv * element.netPrice;
      element.warehouseReceivedQuantity = etv;//Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      element.deliveryQty = etv;//Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      for (let i of this.deliveryNoteDetails) {
        let qty: any;
        if (i.warehouseReceivedQuantity != undefined) {
          if(typeof (i.warehouseReceivedQuantity) != "number"){
            qty = i.warehouseReceivedQuantity.replace(/,/g, "");    
          }else{
            qty = i.warehouseReceivedQuantity;    
          }
             
          totalQty = totalQty + Number(qty);
          totalAmt = totalAmt + ((qty) * parseFloat(element.netPrice));
        }
      }
       this.subContractData[element.podId] = {
        subItemNo: element.itemNo, 
        subItemMaterial:  element.subContractMaterial, 
        billOfMaterial:  element.billOfMaterial, 
        subItemShortText:  element.shortText, 
        subItemReceivedQty: element.deliveryQty
      }

      // element.subContractSubItems[0].subItemReceivedQty = Number(element.deliveryQty).toLocaleString('en',{minimumFractionDigits:3});
      
      this.editDeliveryNotes.controls.totalQty.setValue(totalQty.toLocaleString('en',{minimumFractionDigits:3}));
      this.editDeliveryNotes.controls.totalAmt.setValue(totalAmt.toLocaleString('en',{minimumFractionDigits:2}));
      element.warehouseReceivedQuantity = Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      element.deliveryQty = Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      
    }
    // this.onChangeAmtQty(totalQty,totalAmt);
  }
  // companyCode:any;
  subContractData:any=[];
  async getPONumbers() {
    this.itemList = [];
    let vendorId:any
    ;
    if(this.currentUser.roleID == 3 || this.currentUser.roleID == 7){
      vendorId = this.vendorType.split("-")[0]; 
    }else{
      vendorId = this.currentUser.vendorId
    }
    this.mySpinner=true;
    (await this.cService.getPONumbers(this.plantId, "G", vendorId, this.companyCode)).pipe(first()).subscribe((data) => {
      if (data != null) {
        if (data.responseStatus.toUpperCase() == "SUCCESS") {
          this.dropdownList = data.response;
          for (let i = 0; i <= data.response.length - 1; i++) {
            let obj = {
              id: data.response[i].poId,
              itemName: data.response[i].poNumber
            }
            this.itemList.push(obj);
          }
          this.mySpinner = false;
        } else {
          this.mySpinner = false;
          swal.fire(data.responseMessage, "", "error");
        }
      }
    },
      error => {
        this.mySpinner = false;
      }
    );
  }
  storageMaster = [];
  
  async storageLocationList(plantId) {
    this.mySpinner=true;
    (await this.cService.getstorageLocation(plantId)).pipe(first()).subscribe(async (data) => {
          if (data != null) {
            if (data.responseStatus.toUpperCase() == "SUCCESS") {
              this.storageMaster = data.response;
             this.mySpinner = false;
            } else {
              if(data.responseCode == '401'){
                (await this.cService.getstorageLocation(plantId)).pipe(first()).subscribe((data) => {
                  if (data != null) {
                    if (data.responseStatus.toUpperCase() == "SUCCESS") {
                      this.storageMaster = data.response;
                     this.mySpinner = false;
                    } else {
                      this.mySpinner = false;
                      swal.fire(data.responseMessage, "", "error");
                    }
                  }
                },
                error => {
                  this.mySpinner = false;
                }
              );
              }else{
                this.mySpinner = false;
                swal.fire(data.responseMessage, "", "error");
              }
              
            }
          }
        },
        error => {
          this.mySpinner = false;
        }
      );
  }
  vendorId: any;
  deliveryDetailsByPO: any = [];
  delStatus:any;
  delStatusId:any;
  comment:any=[];
  async getDelivaryNotesByRefNo(id) {
    this.mySpinner = true;
    (await this.cService.getDeliveryNoteByRefNo(id)).pipe(first()).subscribe(async res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        this.delStatus = res.response.deliveryStatus;
        this.delStatusId= res.response.statusId
        this.companyCode = res.response.companyCode;
        
        
        if(res.response.statusId == '4' ){
          this.isCatogary = true;
        }else{
          this.isCatogary = false;
        }
        if(this.currentUser.roleID == '3' || this.currentUser.roleID == '7'){
         await this.getCompanyMasterWare(res.response.sapVendorCode);
        }else{
          await this.getCompanyMaster();
        }
        // if(this.)
        this.mySpinner = false;
        // this.changeCom(this.companyCode);
        await this.getPlantMaster(res.response.companyCode);
        if (this.currentUser.roleID == 3 || this.currentUser.roleID == 7) {
          if(this.delStatus == 'Rejected'){
            this.columnsToDisplay = ["action","delQty","storageLocation","poNumber", "poItem","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ,"totalAmt"]
          }
          
          await this.storageLocationList(res.response.plantCode);
          
        }else{
          if(this.delStatus == 'Rejected'){
            this.columnsToDisplay = ["action","delQty","poNumber", "poItem","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ,"totalAmt"]
          }
        }
        if(res.response.statusId == 3){
          this.pDisable = false;
        }
        if(this.currentUser.roleID == 3 || this.currentUser.roleID == 7){
          this.pDisable = false;
        }
        
        this.deliveryDetailsByPO = res.response;
        let len:any
        len = this.deliveryDetailsByPO.deliveryNoteStatus.length-1;
        if(len > 0){
          this.comment = this.deliveryDetailsByPO.deliveryNoteStatus[len].deliveryStatusComments;
        }
        setTimeout(async () => {
          await this.changePlantSet(res.response);
          // this.setDeliveryNoteData(res.response);  
        }, 500);

      } else {
        // this.showOverlay=false;
        // this.mySpinner = false;
        (await this.cService.getDeliveryNoteByRefNo(id)).pipe(first()).subscribe(async res => {
          if (res.responseStatus.toUpperCase() == "SUCCESS") {
            this.delStatus = res.response.deliveryStatus;
            this.delStatusId= res.response.statusId
            this.companyCode = res.response.companyCode;
            if(res.response.statusId == '4' ){
              this.isCatogary = true;
            }else{
              this.isCatogary = false;
            }
            if(this.currentUser.roleID == '3' || this.currentUser.roleID == '7'){
             await this.getCompanyMasterWare(res.response.sapVendorCode);
            }else{
              await this.getCompanyMaster();
            }
            this.mySpinner = false;
            // this.changeCom(this.companyCode);
            await this.getPlantMaster(res.response.companyCode);
            if (this.currentUser.roleID == 3 || this.currentUser.roleID == 7) {
              if(this.delStatus == 'Rejected'){
                this.columnsToDisplay = ["action","delQty","storageLocation","poNumber", "poItem","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ,"totalAmt"]
              }
              
              await this.storageLocationList(res.response.plantCode);
              
            }else{
              if(this.delStatus == 'Rejected'){
                this.columnsToDisplay = ["action","delQty","poNumber", "poItem","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty", "unitPrice", "poAmount","invoicedAmount" ,"totalAmt"]
              }
            }
            if(res.response.statusId == 3){
              this.pDisable = false;
            }
            if(this.currentUser.roleID == 3 || this.currentUser.roleID == 7){
              this.pDisable = false;
            }
            
            this.deliveryDetailsByPO = res.response;
            let len:any
            len = this.deliveryDetailsByPO.deliveryNoteStatus.length-1;
            if(len > 0){
              this.comment = this.deliveryDetailsByPO.deliveryNoteStatus[len].deliveryStatusComments;
            }
            setTimeout(async () => {
              await this.changePlantSet(res.response);
              // this.setDeliveryNoteData(res.response);  
            }, 500);
    
          } else {
            // this.showOverlay=false;
            this.mySpinner = false;
          }
        },error => {
          this.mySpinner = false;
        });
      }
    },error => {
      this.mySpinner = false;
    });
  }
  poSelected: any = [];
  // :any=[];
  setDeliveryNoteData(data: any): void {
    // this.vendorId = data.vendorId;
    this.plantType = data.plantCode;
    this.deliveryNoteDetails = data.deliveryNoteItemDetails;

    for (let i = 0; i < data.deliveryNoteItemDetails.length; i++) {
      let obj = {
        poId: data.deliveryNoteItemDetails[i].poId,
        poNumber: data.deliveryNoteItemDetails[i].poNumber, isDisabled: true
      };
      this.selectedItems = this.selectedItems.concat({
        poId: data.deliveryNoteItemDetails[i].poId,
        poNumber: data.deliveryNoteItemDetails[i].poNumber
      });
    }
    // this.selectedItems = data.deliveryNoteItemDetails;

    // this.editDeliveryNotes.controls.poNumber.setValue(this.selectedItems);
    this.editDeliveryNotes = this.fb.group({
      // plantType: new FormControl({ value: data.plantCode, disabled: false }),
      // vendorType: new FormControl({ value: data.vendorId + '-' + data.sapVendorCode, disabled: false }),
      dlDate: new FormControl({ value: data.deliveryNoteDate, disabled: false }),
      dlNum: new FormControl({ value: data.deliveryNoteNumberByVendor, disabled: false }),
      categoryType: new FormControl({ value: "", disabled: false }),
      psDate: new FormControl({ value: data.postingDate, disabled: false }),
      hedText: new FormControl({ value: data.headerText, disabled: false }),
      venName: new FormControl({ value: data.deliveryToPersonName, disabled: false }),
      // prGroup: new FormControl({ value:"", disabled:false }),
      postingDate: new FormControl({ value: data.postingDate, disabled: false }),
      totalQty: new FormControl({ value: data.quantity, disabled: false }),
      header: new FormControl({ value: data.headerText, disabled: false }),
      dlPerson: new FormControl({ value: data.deliveryToPersonName, disabled: false }),
      dlNumber: new FormControl({ value: data.deliveryToPersonEmail, disabled: false }),
      shipmentNo: new FormControl({ value: data.shipmentNumber, disabled: false }),
      billOfLading: new FormControl({ value: data.billOfLading, disabled: false }),
      totalAmt: new FormControl({ value: data.totalAmount, disabled: false }),
      category: new FormControl({ value: "001", disabled: false }),
      dlPersonEmail: new FormControl({ value: data.deliveryToPersonEmail, disabled: false }),
      delQtys: new FormControl({ value: "", disabled: false }),
      // poNumber: new FormControl(this.selectedItems, Validators.required),
    });
    this.editPostDate = this.editDeliveryNotes.value.psDate;
    // this.editDeliveryNotes.
    // this.editDeliveryNotes.controls.vendorType.setValue(data.vendorId + '-' + data.sapVendorCode);
    this.editDeliveryNotes.controls.shipmentNo.setValue(data.shipmentNumber);
    this.documents = data.deliveryNoteSupportDocs;

    // setTimeout(() => {

    // this.vendorId = data.vendorId;  
    // }, 200);

  }
  public onDropDownClose(item: any) {
  }

  get f() {
    return this.editDeliveryNotes.controls;
  }


  get productInformationMasters() {
    return this.editDeliveryNotes.get("productInformationMasters") as FormArray;
  }
  goTo() {
    this.router.navigate(["delivery-notes"]);
  }

  changeQty(event: any) {
    if (event.target.value) {
    }
  }

  public onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.certEscolar = file;
      this.fileCount++;
    }
  }

  async getDeliveryNoteDetails(item: any) {
    this.deliveryNoteDetails=[];
    let selectedItem = [];
    for (let i = 0; i <= this.selectedItems.length - 1; i++) {
      selectedItem.push(this.selectedItems[i].poId);
    }
    let request = {
      "poIds": selectedItem,
      "plantCode": this.plantId,
      "typeOfInvoice": "G"
    }
    this.mySpinner =true;
    
    (await this.cService.getDeliveryNotedDetailsPO(request)).pipe(first()).subscribe((data) => {
      if (data != null) {
        if (data.responseStatus.toUpperCase() == "SUCCESS") {
          let reqDat=[];
          let newdata =[] = data.response;
          let res2 =[] = newdata.filter((page1) => this.masterDeliveryNoteDetails.some(page2 => page1.id != page2.poId ))
         for(let i of res2){
           let obj =
           {
            
            "grQuantity": i.grQuantity,
            "warehouseReceivedQuantity": parseFloat(i.warehouseReceivedQuantity).toLocaleString('en', {minimumFractionDigits:3}),
            "storageLocation": i.storageLocation,
            "poId": i.poId,
            "id":i.id,
            "podId": i.podId,
            "poNumber": i.poNumber,
            "poItem": i.poItem,
            "shortText": i.shortText,
            "material": i.material,
            "materialGroup": i.materialGroup,
            "scheduledQuantity": i.scheduledQuantity,
            "purchaseOrderGrQuantity": i.purchaseOrderGrQuantity,
            "outStandingQuantity": i.outStandingQuantity,
            "outStandingUnit":i.outStandingUnit,
            "poDeliveryDate":i.poDeliveryDate,
            "priceUnit": i.priceUnit,
            "unit":i.outStandingUnit,
            "netPrice": i.netPrice,
            "grossValue": i.grossValue,
            "amount": i.amount,
            "totalAmt": parseFloat(i.warehouseReceivedQuantity) * parseFloat(i.netPrice) ,
            "category": this.categorySelected,
            "totalPenQty": i.outStandingQuantity,
            "subContractSubItems": i.subContractSubItems
           }
           reqDat.push(obj);
         }
          this.deliveryNoteDetails = this.masterDeliveryNoteDetails.concat(reqDat);
          this.mySpinner = false;
        
        }
        else {
          this.mySpinner = false;
          swal.fire(data.responseMessage, "", "error");
        }
      }
    },
      error => {
        this.mySpinner = false;
      }
    );
  }
  supportDocs: any = [];
  delQty: any = [];
  onSubmit() {
    let data1: any = [];
    let subData: any = [];
    let mainObj = {};
    let category;
    let storageLocation;
    // subData={
    for (let i = 0; i < this.deliveryNoteDetails.length; i++) {
      if(this.deliveryNoteDetails[i].itemCategory != undefined || this.deliveryNoteDetails[i].itemCategory != null){
       if (this.deliveryNoteDetails[i].itemCategory == "L") {
        let obj = {
                subItemNo: this.deliveryNoteDetails[i].itemNo,
                subItemMaterial: this.deliveryNoteDetails[i].subContractMaterial,
                subItemShortText: this.deliveryNoteDetails[i].shortText,
                subItemReceivedQty: this.deliveryNoteDetails[i].requiredQty,
              };
              subData.push(obj);
      }
    }
      // if (this.currentUser.roleID == '3') {
      //   if (this.deliveryDetails[i].subContractSubItems.length > 0) {
      //     let sub = this.deliveryDetails[i].subContractSubItems;
      //     for (let j = 0; j < sub.length - 1; j++) {
      //       let obj = {
      //         subItemNo: sub[j].SubItemNo,
      //         subItemMaterial: sub[j].MaterialNo,
      //         subItemShortText: sub[j].ShortText,
      //         subItemReceivedQty: this.subQty[j],
      //       };
      //       subData.push(obj);
      //     }

      //   }
      // }
      
    //   { categoryId: "005", categoryName: "Sub Contract GR (MIGO #005)" },
    // { categoryId: "008", categoryName: "GR of Scheduling Agreement (MIGO #008)" },
      // if(this.currentUser.roleID == '2'){
        if(this.deliveryNoteDetails[i].category == "Sub Contract GR (MIGO #005)"){
          category = "005";  
        }else if(this.deliveryNoteDetails[i].category == "GR of Scheduling Agreement (MIGO #008)"){
          category = "008";  
        }else{
          category = this.deliveryNoteDetails[i].category;
        }
      // }else{
        
      //   storageLocation = this.deliveryNoteDetails[i].storageLocation;
      // }

      let obj:any=[];
       
      if(subData[i] == null || subData[i] == undefined){
        obj = [];
      }else{
        obj.push(subData[i]);
      }
      if (this.currentUser.roleID == 3 || this.currentUser.roleID == 7) {
        let subcontract;
        if(this.deliveryNoteDetails[i].subContractSubItems != null){
          subcontract = this.deliveryNoteDetails[i].subContractSubItems;
        }else{
          subcontract = [];
        }
        mainObj = {
      
          id: this.deliveryNoteDetails[i].id,
          poNumber: this.deliveryNoteDetails[i].poNumber,
          poItem: this.deliveryNoteDetails[i].poItem,

          // category fetch based on 
          category: category,
          // materialG: this.deliveryNoteDetails[i].materialG,
          // materialNo: this.deliveryNoteDetails[i].materialNo,
          poId: this.deliveryNoteDetails[i].poId,
          podId: this.deliveryNoteDetails[i].podId,
          storageLocation: this.deliveryNoteDetails[i].storageLocation, //warehouse
          // shortText: this.deliveryNoteDetails[i].shortText,
          // poQty: this.deliveryNoteDetails[i].scheduledQuantity,
          grQuantity: this.deliveryNoteDetails[i].grQuantity,
          rowVersion:this.deliveryNoteDetails[i].rowVersion,
          // pendingQty: this.deliveryNoteDetails[i].outStandingQuantity,
          // unitPrice: this.deliveryNoteDetails[i].netPrice,
          // poAmount: this.deliveryNoteDetails[i].grossValue,
          warehouseReceivedQuantity: this.deliveryNoteDetails[i].warehouseReceivedQuantity,
          amount:Math.round(this.deliveryNoteDetails[i].totalAmt * 100)/100,
          subContractSubItems: subcontract
        };
      } else {
        mainObj = {
          id: this.deliveryNoteDetails[i].id,
          poNumber: this.deliveryNoteDetails[i].poNumber,
          poItem: this.deliveryNoteDetails[i].poItem,
          // category fetch based on 
          category: category,
          // materialG: this.deliveryNoteDetails[i].materialG,
          // materialNo: this.deliveryNoteDetails[i].materialNo,
          poId: this.deliveryNoteDetails[i].poId,
          podId: this.deliveryNoteDetails[i].podId,
          // storageLocation: "Head Office",//this.deliveryNoteDetails[i].storageLocation, //warehouse
          // shortText: this.deliveryNoteDetails[i].shortText,
          // poQty: this.deliveryNoteDetails[i].scheduledQuantity,
          grQuantity: this.deliveryNoteDetails[i].grQuantity,
          rowVersion:this.deliveryNoteDetails[i].rowVersion,
          // pendingQty: this.deliveryNoteDetails[i].outStandingQuantity,
          // unitPrice: this.deliveryNoteDetails[i].netPrice,
          // poAmount: this.deliveryNoteDetails[i].grossValue,
          warehouseReceivedQuantity: this.deliveryNoteDetails[i].warehouseReceivedQuantity,
          amount:Math.round(this.deliveryNoteDetails[i].totalAmt * 100)/100,
          subContractSubItems: this.deliveryNoteDetails[i].subContractSubItems
        };
      }

      data1.push(mainObj);
    }


    this.supportDocs = JSON.parse(localStorage.getItem("supportDocs"));
    // var test: any;

    let documents: any;
    let temp: any = [];
    if (this.supportDocs != null && this.supportDocs != undefined) {
      for (let i = 0; i <= this.supportDocs.length - 1; i++) {
        documents = {
          "id": this.supportDocs[i].id
        }
        temp.push(documents);
      }
    }
    let statusId;
    let vendorId;
    let sapVendorCode;
    if (this.currentUser.roleID == 3 || this.currentUser.roleID == 7) {
      // statusId = '2';// warehouse
      vendorId = this.vendorType.split("-")[0];
      sapVendorCode = this.vendorType.split("-")[1];
      // sapVendorCode = "1001651";
    } else {
      // statusId = '1';// vendor 
      vendorId = this.currentUser.vendorId;
      sapVendorCode = this.currentUser.usertype;
    }

    let dndata = {
      deliveryNoteDate: this.datepipe.transform(this.editDeliveryNotes.value.dlDate,'yyyy-MM-dd'),
      plantCode: this.plantId,
      companyCode: this.companyCode,
      deliveryNoteRefNumVp: this.deliveryDetailsByPO.deliveryNoteRefNumVp,
      postingDate: this.datepipe.transform(this.editDeliveryNotes.value.psDate,'yyyy-MM-dd'),
      quantity: this.editDeliveryNotes.value.totalQty,
      headerText: this.editDeliveryNotes.value.hedText,
      deliveryToPersonName: this.editDeliveryNotes.value.dlPerson,
      shipmentNumber: this.editDeliveryNotes.value.shipmentNo,
      totalAmount: this.editDeliveryNotes.value.totalAmt,

      // statusId: statusId, 
      deliveryToPersonEmail: this.editDeliveryNotes.value.dlPersonEmail,
      // deliveryNoteRefNumVp: this.editDeliveryNotes.value.dlNum,
      vendorId: vendorId,
      billOfLading: this.editDeliveryNotes.value.billOfLading,
      dnAddedByType: "V",
      isApproved: "Y",
      sapVendorCode: sapVendorCode,
      deliveryStatus: this.deliveryDetailsByPO.deliveryStatus,
      deliveryNoteStatus: this.deliveryDetailsByPO.deliveryNoteStatus,
      deliveryNoteNumberByVendor: this.editDeliveryNotes.value.dlNum,
      deliveryNoteItemDetails: data1,
      deliveryNoteSupportDocs: temp,
    };
    let nameregex = /^[a-zA-Z ]*$/;
    let emailregex = /^([\w-]|(?<!\.)\.)+[a-zA-Z0-9]@[a-zA-Z0-9]([\w\-]+)((\.([a-zA-Z]){2,9})+)$/
    if (
      this.editDeliveryNotes.value.dlNum == "" ||
      this.editDeliveryNotes.value.dlNum == undefined
    ) {
      swal.fire(`${this.language.swaldn}`, "", "warning");
      return;
    } 
    // else if (
    //   this.editDeliveryNotes.value.shipmentNo == "" ||
    //   this.editDeliveryNotes.value.shipmentNo == undefined
    // ) {
    //   swal.fire("Please enter shipment number");
    //   return;
    // } 
    else if (
      this.editDeliveryNotes.value.dlDate == "" ||
      this.editDeliveryNotes.value.dlDate == undefined
    ) {
      swal.fire(`${this.language.swaldndate}`, "", "warning");
      return;
    } else if (
      this.editDeliveryNotes.value.totalQty == "" ||
      this.editDeliveryNotes.value.totalQty == undefined || this.editDeliveryNotes.value.totalQty == 0
    ) {
      swal.fire(`${this.language.swaltotqty}`, "", "warning");
      return;
    } else if (
      this.editDeliveryNotes.value.totalAmt == "" ||
      this.editDeliveryNotes.value.totalAmt == undefined
    ) {
      swal.fire(`${this.language.swalamount}`, "", "warning");
      return;
    } 
    // else if (
    //   this.editDeliveryNotes.value.dlPerson == "" ||
    //   this.editDeliveryNotes.value.dlPerson == undefined
    // ) {
    //   swal.fire("Please enter delivery person name", "", "warning");
    //   return;
    // } 
    //  else if (
    //   this.editDeliveryNotes.value.dlPersonEmail == "" ||
    //   this.editDeliveryNotes.value.dlPersonEmail == undefined
    // ) {
    //   swal.fire("Please enter delivery person email", "", "warning");
    //   return;
    // } 
   if(this.editDeliveryNotes.value.dlPersonEmail != ""){
    if(!emailregex.test(this.editDeliveryNotes.value.dlPersonEmail)){
      swal.fire(`${this.language.swalemail}`, "", "warning");
      return;
    } 
  }
    // else if (
    //   this.editDeliveryNotes.value.hedText == "" ||
    //   this.editDeliveryNotes.value.hedText == undefined
    // ) {
    //   swal.fire("Please enter header text", "", "warning");
    //   return;
    // } 
    if(this.currentUser.roleID == "3" || this.currentUser.roleID == "7" ){
      if (
      this.editDeliveryNotes.value.category == "" ||
      this.editDeliveryNotes.value.category == undefined
    ) {
      swal.fire(`${this.language.swalcategory}`, "", "warning");
      return;
    }
  }
  if(this.editDeliveryNotes.value.totalQty <= 0){
    swal.fire(`${this.language.swalqty}`, "", "warning");
    return;
  }
    
    if(this.statusId == 4 && (this.currentUser.roleID == 3 || this.currentUser.roleID == 7)){
      this.releaseStock(dndata);
    }else{
      this.addDeliveryNote(dndata);
    }
  }
  hedText(e) {
    if (e.target.value.length > 25) {
      swal.fire(`${this.language.swalc2}`, "", "warning");

      return;
    }
  }
  dlPerson(e) {
    if (e.target.value.length > 12) {
      swal.fire(`${this.language.swalc3}`, "", "warning");

      return;
    }
  }

  dlNum(e) {
    if (e.target.value.length > 16) {
      swal.fire(`${this.language.swalc1}`, "", "warning");

      return;
    }
  }
  billOfLading(e) {
    if (e.target.value.length > 16) {
      swal.fire(`${this.language.swalc1}`, "", "warning");

      return;
    }
  }

  async addDeliveryNote(row_obj) {
    this.mySpinner = true;

    (await this.cService
      .updateDeliveryNote(this.routeFromD, row_obj))
      .pipe(first())
      .subscribe(
        (data) => {
          // if (data != null) {
            if (data.responseStatus.toUpperCase() == "SUCCESS") {
              // this._snackBar.open(data.responseMessage, "Ok", {
              //   duration: 5000,
              // });
              // this.dataSource = data;
              this.mySpinner = false;
              localStorage.setItem("searchData", "")
              localStorage.setItem("tableData", "")
              // swal.fire(data.responseMessage);
              swal.fire({
                icon: 'success',
                text: data.responseMessage
              })
              swal.fire("VPDN# "+ data.response.deliveryNoteRefNumVp.substring(data.response.deliveryNoteRefNumVp.indexOf('_')+1), data.responseMessage, 'success');
              // this.getDeliveryNoteList(null);
              this.router.navigate(["delivery-notes"]);
            } else {
              this.mySpinner = false;
              // swal.fire(data.responseMessage);
              swal.fire({
                icon: 'error',
                text: data.responseMessage
              })
            }
          // }
          // this.showOverlay=false;
        },
        error => {
          
          this.mySpinner = false;
          swal.fire(error.error.error, "", "error");
        }
      );
  }

  async upload(event: any) {
    let formData = new FormData();
    formData.append("files", event.target.files[0]);
    if(event.target.files[0].size > 5000000){
      swal.fire(`${this.language.swalsize}`, "", "warning");
      this.myInputVariable.nativeElement.value = "";
      return
     }else{
      this.supportDocs = JSON.parse(localStorage.getItem("supportDocs"));
      if (this.supportDocs != null && this.supportDocs != undefined) {
        for (let i = 0; i <= this.supportDocs.length - 1; i++) {
          if(this.supportDocs[i].documentName == event.target.files[0].name){
            
            swal.fire(`${this.language.swaldiffile}`, "", "warning");
            this.myInputVariable.nativeElement.value = "";
            return
         }
        }
        if(this.supportDocs.length >= 3){
          swal.fire(`${this.language.swalmaxfile}`, "", "warning");
          this.myInputVariable.nativeElement.value = "";
          return
        }
      }
    (await this.cService.upload(formData)).pipe(first()).subscribe(res => {
      if(res.responseStatus.toUpperCase() == "SUCCESS"){
      let test: any = res.response[0];//["message"].uploads;

      // for (let i = 0; i <= test.length - 1; i++) {
      // let path = test[i].path.replace(/\\/g, "/");
      if(res.responseStatus.toUpperCase() == 'SUCCESS'){
        this.isReject = true;
      }
      this.listValue = [];
      
      this.listValue.push({
        "id": test.id,
        "documentType": test.documentType,
        "documentPath": test.documentPath,
        "documentName": test.documentName,
        "documentExtension": test.documentExtension,
        "documentSize": test.documentSize,
      });

      var last = this.listValue.slice(-1).pop(); /**Extract Last value  **/

      var obj = last;
      this.datacache.push(obj); /**Push Data value  **/

      let supportDoc: any = [];

      supportDoc = JSON.parse(localStorage.getItem("supportDocs"));
      let arr: any = [];
      if (supportDoc == null) {
        localStorage.setItem("supportDocs", JSON.stringify(this.listValue));
      } else {
        for (let i = 0; i <= this.listValue.length - 1; i++) {
          supportDoc.push(this.listValue[i]);
        }
        var result = supportDoc.reduce((unique, o) => {
          if (!unique.some(obj => obj.name === o.name)) {
            unique.push(o);
          }
          return unique;
        }, []);
        //let docs = this.listValue.concat(arr);
        localStorage.setItem("supportDocs", JSON.stringify(supportDoc));
      }
      this.listValue = JSON.parse(localStorage.getItem("supportDocs"));
    }else {
      swal.fire(res.responseMessage, "", "error");
    }
  })
  }
  }
  categorySelected: any;
  onCategoryChange(event: any) {
    this.cate= event.source.value;

    this.categorySelected = event.source.value;
    let reqDat = [];
    let newDeliveryData = [];
    for(let i of this.deliveryNoteDetails){
      // for(let i of res2){
        let obj = {
         
         "grQuantity": i.grQuantity,
         "warehouseReceivedQuantity": i.warehouseReceivedQuantity,
         "storageLocation": i.storageLocation,
         "id": i.id,
         "poId":i.poId,
         "podId": i.podId,
         "poNumber": i.poNumber,
         "poItem": i.poItem,
         "shortText": i.shortText,
         "material": i.material,
         "materialGroup": i.materialGroup,
         "scheduledQuantity": i.scheduledQuantity,
         "purchaseOrderGrQuantity": i.purchaseOrderGrQuantity,
         "outStandingQuantity": i.outStandingQuantity,
         "outStandingUnit":i.outStandingUnit,
         "poDeliveryDate":i.poDeliveryDate,
         "priceUnit": i.priceUnit,
         "netPrice": i.netPrice,
         "grossValue": i.grossValue,
         "amount": i.amount,
         "totalAmt": i.totalAmt,
         "category": this.categorySelected,
         "totalPenQty": i.totalPenQty,//Number(i.dnQty) + Number(i.outStandingQuantity),
         "subContractSubItems": i.subContractSubItems
        }
        reqDat.push(obj);
      
       
    }
    this.deliveryNoteDetails = [];
    this.deliveryNoteDetails = newDeliveryData.concat(reqDat);
    
 


  }
  // onDelQtyChange(event: any, element: any) {
  //   let etv = (event.target.value).replace(/,/g, "");
  //   etv = Number(etv);
  //   if(event.target.value != ""){
  //     if (parseFloat(element.totalPenQty) < etv) {
  //       event.target.value = 0;
  //       element.totalAmt = 0;
  //       element.warehouseReceivedQuantity = 0;
  //       let totalQty:number = 0;
  //       let totalAmt:number = 0;
     
  //       for (let i = 0; i < this.deliveryNoteDetails.length; i++) {
  //         if(this.deliveryNoteDetails[i].warehouseReceivedQuantity == undefined){
  //           totalQty = totalQty + 0;
  //           totalAmt = totalAmt + 0;
  //         }else{
  //           totalQty = totalQty + parseFloat(this.deliveryNoteDetails[i].warehouseReceivedQuantity);
  //           totalAmt = totalAmt + parseFloat(this.deliveryNoteDetails[i].totalAmt);//(parseFloat(this.deliveryNoteDetails[i].warehouseReceivedQuantity) * parseFloat(element.netPrice));
  //         }
          
          
  //       }
  //       // this.editDeliveryNotes.controls.totalQty.setValue(0);
  //       // this.editDeliveryNotes.controls.totalAmt.setValue(0);
  //       swal.fire("Delivery qty should be less than Pending Qty", "", "warning");

  //       this.editDeliveryNotes.controls.totalQty.setValue(totalQty.toLocaleString('en', {
  //         minimumFractionDigits: 3
  //     }));
  //       this.editDeliveryNotes.controls.totalAmt.setValue(totalAmt.toLocaleString('en', {
  //         minimumFractionDigits: 2
  //     }));
  //     element.warehouseReceivedQuantity = Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      
  //     } else {
  //       element.totalAmt = etv * parseFloat(element.netPrice);
  //       element.warehouseReceivedQuantity = etv;
  //       let totalQty:number = 0;
  //       let totalAmt:number = 0;
  //       for (let i = 0; i < this.deliveryNoteDetails.length; i++) {
  //         if(this.deliveryNoteDetails[i].warehouseReceivedQuantity == undefined){
  //           totalQty = totalQty + 0;
  //           totalAmt = totalAmt + 0;
  //         }else{
  //           totalQty = totalQty + parseFloat(this.deliveryNoteDetails[i].warehouseReceivedQuantity);
  //           totalAmt = totalAmt + parseFloat(this.deliveryNoteDetails[i].totalAmt);//(parseFloat(this.deliveryNoteDetails[i].warehouseReceivedQuantity) * parseFloat(element.netPrice));
  //         }
          
          
  //       }
  //       this.editDeliveryNotes.controls.totalQty.setValue(totalQty.toLocaleString('en', {
  //         minimumFractionDigits: 3
  //     }));
  //       this.editDeliveryNotes.controls.totalAmt.setValue(totalAmt.toLocaleString('en', {
  //         minimumFractionDigits: 2
  //     }));
  //     element.warehouseReceivedQuantity = Number(etv).toLocaleString('en',{minimumFractionDigits:3});
  //     }
  //   }else{
  //     let totalQty:number = 0;
  //     let totalAmt:number = 0;
  //     for (let i = 0; i < this.deliveryNoteDetails.length; i++) {
  //       if(this.deliveryNoteDetails[i].podId == element.podId){
  //         this.deliveryNoteDetails[i].warehouseReceivedQuantity = etv;
  //       }
  //       if(this.deliveryNoteDetails[i].warehouseReceivedQuantity == undefined){
  //         totalQty = totalQty + 0;
  //         totalAmt = totalAmt + 0;
  //       }else{
  //         totalQty = totalQty + parseFloat(this.deliveryNoteDetails[i].warehouseReceivedQuantity);
  //         totalAmt = totalAmt + (parseFloat(this.deliveryNoteDetails[i].warehouseReceivedQuantity) * parseFloat(element.netPrice));
  //       }
  //       }
  //     this.editDeliveryNotes.controls.totalQty.setValue(totalQty.toLocaleString('en', {minimumFractionDigits:3}));
  //     this.editDeliveryNotes.controls.totalAmt.setValue(totalAmt.toLocaleString('en', {minimumFractionDigits:2}));
  //   }
    
  //   if(element.subContractSubItems.length > 0){
  //     this.onDelQtyChangeSub1(event, element);
  //   }
    
  // }
  onDelQtyChange(event: any, element: any){
    let etv=0;
    etv = event.target.value.replace(/,/g, "");
    let totalQty = 0;
    let totalAmt:any = 0;
    let WQty:any;
    let TQty:any;
    if(typeof(element.WRQty) == "number"){
      WQty = element.WRQty;
    }else{
      WQty = element.WRQty.replace(/,/g, "");
    }
    if(typeof(element.totalPenQty) == "number"){
      TQty = element.totalPenQty;
    }else{
      TQty = element.totalPenQty.replace(/,/g, "");
    }
    WQty = Number(WQty);
    TQty = Number(TQty);
    let totalQtyPending = WQty + TQty;
    if (parseFloat(totalQtyPending) < etv) {
      event.target.value = 0;
      element.totalAmt = 0;
      element.warehouseReceivedQuantity = 0;
      let totalQty:number = 0;
      let totalAmt:number = 0;
     
      for (let i = 0; i < this.deliveryNoteDetails.length; i++) {
        if(this.deliveryNoteDetails[i].warehouseReceivedQuantity == undefined){
          totalQty = totalQty + 0;
          totalAmt = totalAmt + 0;
        }else{
          totalQty = totalQty + parseFloat(this.deliveryNoteDetails[i].warehouseReceivedQuantity);
          totalAmt = totalAmt + (parseFloat(this.deliveryNoteDetails[i].warehouseReceivedQuantity) * parseFloat(element.netPrice));
        }
        
        
      }
      swal.fire(`${this.language.swalLPQ}`, "", "warning");
    } else {
      // etv = (event.target.value).replace(/,/g, "");
      element.totalAmt = Number(etv) * element.netPrice;
      element.warehouseReceivedQuantity = etv; //Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      
      for (let i of this.deliveryNoteDetails) {
        
        if (i.warehouseReceivedQuantity != undefined) {
          let qty = i.warehouseReceivedQuantity.replace(/,/g, "");
          totalQty = totalQty + Number(qty);
          totalAmt = totalAmt + i.totalAmt;
          //totalAmt=1234566;
        }
      }
      
    }
    totalAmt = totalAmt.toFixed(2);
    totalAmt = Number(totalAmt);
    this.editDeliveryNotes.controls.totalQty.setValue(totalQty.toLocaleString('en', {
      minimumFractionDigits: 3
  }));
  this.editDeliveryNotes.controls.totalAmt.setValue(totalAmt.toLocaleString('en', {
    minimumFractionDigits: 2
}));
    if(element.subContractSubItems.length > 0){
      this.onDelQtyChangeSub1(event, element);
    }
  }
  onDelQtyChangeSub1(event:any, element:any){
    if(event.target.value != NaN){
      element.subContractSubItems[0].subItemReceivedQty = Number(event.target.value).toLocaleString('en', {minimumFractionDigits:3});
    }else{
      element.subContractSubItems[0].subItemReceivedQty = (0).toLocaleString('en', {minimumFractionDigits:3});
    }
    
  }

  newDocuments: any = [];
  deleteItem(id) {
    this.listValue = JSON.parse(localStorage.getItem("supportDocs"));
    for (let i = 0; i <= this.listValue.length - 1; i++) {
      if (id == this.listValue[i].id) {

      } else {
        this.newDocuments.push(this.listValue[i]);
      }
    }
    localStorage.setItem("supportDocs", JSON.stringify(this.newDocuments));
    this.getDocus();
  }
  getDocus() {
    this.newDocuments = [];
    this.listValue = JSON.parse(localStorage.getItem("supportDocs"));
  }
  selectStorageLocation:any =[];
  onStorageChange(event: any, element: any,locationCode:any) {
    this.isReject = true;
    element.storageLocation = event.source.value;
    for (let i of this.deliveryNoteDetails) {
      if (element.id == i.id) {
        i.storageLocation = event.source.value;
        this.selectStorageLocation = i.storageLocation;
      }
    }

  }
  async releaseStock(data) {
    this.mySpinner = true;
    (await this.cService.releaseCategoryStock(this.routeFromD,this.statusId,data)).pipe(first()).subscribe((res) => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        this.mySpinner = false;
        localStorage.setItem("searchData", "")
        localStorage.setItem("tableData", "")
        // swal.fire(res.responseMessage, "", "success");
        swal.fire({
          icon: 'success',
          text: res.responseMessage
        })
        swal.fire("VPDN# "+ res.response.deliveryNoteRefNumVp.substring(res.response.deliveryNoteRefNumVp.indexOf('_')+1), res.responseMessage, 'success');
        this.router.navigate(['delivery-notes'])
      }else{
        this.mySpinner = false;
        swal.fire(res.responseMessage, "", "error");
      }
      
    })
  }
  async rejectStock() {
    let id;
    (await this.cService.rejectCategoryStock(this.routeFromD)).pipe(first()).subscribe((data) => {
    })
  }
  onCancel(){
    this.router.navigate(['delivery-notes'])
  }
    async downLoad(id: any) {
    this.mySpinner = true;
    (await this.cService.download(id)).pipe(first()).subscribe(res => {
      this.mySpinner = false;
    }, error => {
      this.mySpinner = false;
    })
  }
  // keyPressNumbers(event:any) {
  //   var charCode = (event.which) ? event.which : event.keyCode;
  //   // Only Numbers 0-9
  //   if ((charCode < 48 || charCode > 57)) {
  //     event.preventDefault();
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;    
    // Only Numbers 0-9    
    if ((charCode < 48 || charCode > 57 ) && (charCode != 46)) {    
    event.preventDefault();    
    return false;    
    } else {    
    return true;    
    }    
    }
  onPoInfo(poNumber){
    this.router.navigate(["delivery-notes/po-info", poNumber, 'e', this.routeFromD]);
    
  }
  toggle(){
    this.lCategory=false;
  }
  Space(event:any){
    if( event.key === ' ' && event.target.selectionStart === 0){
      event.preventDefault(); 
    }
  }  
  keypressalpha(event:any){
    var inp = String.fromCharCode(event.keyCode);
    if (/^[a-zA-Z ]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  editPostDate:any;
  changeInput(event:any){
    this.editPostDate = event.target.value;
   this.isReject = true;
  }
 
  allowTwodigit(event: any) {
    if (event.keyCode === 46 && event.target.value.split('.').length === 2) {
      return false;      
      }
    if (
      event.target.value.indexOf(".") > -1 &&
      event.target.value.split(".")[1].length > 2
    ) {
      // alert('Two numbers only allowed after decimal point');
      event.preventDefault();
      swal.fire(`${this.language.swaldec}`, "", "warning");
      return;
    }
  }
  // restrictZero (event:any){
  //   if (event.target.value  <= 0){
  //     swal.fire("Delivery quantity should be greater than zero");
  //   }
  // }
  notAllow(event: any) {
    if (event.target.value.length == 0 && event.which == 46) {
      event.preventDefault();
      swal.fire(`${this.language.swalfdec}`, "", "warning");
      return;
    }
  }

  notAllowHyphen(e){
    if (e.which == 45) {
      e.preventDefault();
      swal.fire(`${this.language.swalneg}`, "", "warning");
      return;
    }
  }

  blurFunction(event,element){
    let targetV:any;
    targetV = (event.target.value).replace(/,/g, "");
    targetV = Number(targetV);
    event.target.value = (targetV).toLocaleString('en', { minimumFractionDigits: 3 });
    this.onDelQtyChangeSub(event, element);
  }
  blurFunction1(event,element){
    let targetV:any;
    targetV = (event.target.value).replace(/,/g, "");
    targetV = Number(targetV);
    event.target.value = (targetV).toLocaleString('en', { minimumFractionDigits: 3 });
  }
  removeItem(id) {
    let newData: any = [];
    for (let i of this.deliveryDetails) {
      if (i.purchaseOrderDetailsId == id) {
      } else {
        newData.push(i);
      }
    }

    this.deliveryDetails = newData;
    let totalQty = 0;
    let totalAmt = 0;
    for (let i of this.deliveryDetails){
      if(i.warehouseReceivedQuantity != undefined){
        if(i.totalAmt != undefined){
          totalQty = totalQty + Number(i.warehouseReceivedQuantity);
          totalAmt = totalAmt + i.totalAmt;
        }else{
          totalQty = totalQty + Number(i.warehouseReceivedQuantity);
          totalAmt = totalAmt + i.amount;
        }
      }
    }
    this.editDeliveryNotes.controls.totalQty.setValue(totalQty.toLocaleString('en',{minimumFractionDigits:3}));
  this.editDeliveryNotes.controls.totalAmt.setValue(totalAmt.toLocaleString('en',{minimumFractionDigits:2}));    
  }
}
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];