import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

import { DatePipe, formatDate } from "@angular/common";
import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { first } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CommonServicesService } from "src/app/services/common-services.service";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import swal from "sweetalert2";
import { ThrowStmt } from "@angular/compiler";
import { AdminServiceService } from "src/app/services/admin-service.service";
import { formatNumber } from "@angular/common";

@Component({
  selector: "app-add-delivery-notes",
  templateUrl: "./add-delivery-notes.component.html",
  styleUrls: ["./add-delivery-notes.component.css"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class AddDeliveryNotesComponent implements OnInit {
  emailPattern =
    /^([\w-]|(?<!\.)\.)+[a-zA-Z0-9]@[a-zA-Z0-9]([\w\-]+)((\.([a-zA-Z]){2,9})+)$/;
  expandedElement: PeriodicElement | null;
  showOverlay: boolean;
  certEscolar: any = "";
  fileCount = 0;
  subQty: any = [];
  vendorList: any = [];
  deliveryDetails: any = [];
  show: boolean = false;
  lCategory: boolean = false;
  isActiveDiv: boolean;
  isShow: boolean;
  showCN: boolean = false;
  showPO: boolean = false;
  language:any;
  @ViewChild("myInput")
  myInputVariable: ElementRef;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public _snackBar: MatSnackBar,
    private cService: CommonServicesService,
    private aService: AdminServiceService,
    public datepipe: DatePipe,
  ) {}
  // @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
  //   swal.fire(`${this.language.swalcp}`);
  //   e.preventDefault();
  // }
  // @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
  //   swal.fire(`${this.language.swalcp}`);
  //   e.preventDefault();
  // }
  // @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
  //   e.preventDefault();
  // }
  deliveryDate = new Date(new Date().getTime() - 0);
  deliveryDate1 = new Date(new Date().getTime() - 0);
  addDeliveryNotes: FormGroup = this.fb.group({
    // poNumber: ["", [Validators.required]],
    // plantType: ["", [Validators.required]],
    // vendorType: ["", [Validators.required]],
    billOfLading: [""],
    dlDate: ["", [Validators.required]],
    dlqnty: ["", [Validators.required]],
    categoryType: ["", [Validators.required]],
    dlNum: ["", [Validators.required]],
    psDate: ["", [Validators.required]],
    hedText: [""],
    venName: ["", [Validators.required]],
    // prGroup: ["", [Validators.required]],
    postingDate: ["", [Validators.required]],
    totalQty: ["", [Validators.required]],
    header: ["", [Validators.required]],
    dlPerson: [""],
    dlNumber: ["", [Validators.required]],
    shipmentNo: [""],
    billOfLanding: [""],
    totalAmt: ["", [Validators.required]],
    category: ["", [Validators.required]],
    dlPersonEmail: [
      "",
      [
        Validators.email,
        Validators.pattern(this.emailPattern),
      ],
    ],
    delQtys: ["", [Validators.required]],
    productInformationMasters: this.fb.array([]),
  });
  date: any;
  dropdownList = [];
  // selectedItems = [];
  dropdownSettings = {};
  dropdownSettings1 = {};
  dropdownSettings2 = {};
  dropdownSettings4 = {};
  columnsToDisplay = [];
  poListDropdown: any = {};
  poList: any;
  poItems: any;
  selectedItems = [];
  totalAmt: any;
  deliveryQty: any;
  unitPrice: any;
  dataSource: any = [];
  displayData: boolean = false;
  currentUser: any = [];
  mySpinner: boolean = false;

  async ngOnInit(): Promise<void> {
    localStorage.removeItem("supportDocs");
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    let verifyRole:any = [2,3,6,7];
    if(lang == 'en'){
      this.language = {
        "head":"Add Delivery Note",
        "companyName":	"Company Name *",
        "plantName":	"Plant Name *",
        "PONumber":	"PO Number",
        "deliveryNoteDate" :	"Delivery Note Date",
        "deliveryNoteNumber" :	"Delivery Note Number",
        "postingDate" :	"Posting Date",
        "totalQuantity" :	"Total Quantity",
        "totalAmount" :	"Total Amount",
        "headerText":	"Header Text",
        "deliveryPersonEmail":	"Delivery Person Email",
        "deliveryPersonName":	"Delivery Person Name",
        "attachments":"Attachments",
        "vendorCode":"Vendor Code *",
        "deliveryNoteDetails":	"Delivery Note Details",
        "actions":	"actions",
        "deliveringQuantity":	"Delivering Quantity",
        "POItem"	:"PO Item",
        "materialNo"	:"Material No",
        "shortText":	'Short Text',
        "POQTY":	"PO QTY",
        "unit":	"Unit",
        "deliveryDate"	:"Delivery Date",
        "receivedQTY"	:"Received QTY",
        "pendingQTY":	"Pending QTY",
        "unitPrice"	:"Unit Price",
        "itemAmount":	"Item Amount",
        "invoicedAmount":	"GR Amount",
        "category":"Category",
        "storageLocation":"Storage Location",
        "deliveringQty":"Delivering Qty",
        "subItemNo":"Sub Item No",
        "bom":"BOM",
        "submit":"Submit",
        "cancel":"Cancel",
        "note":"Note",
        "noteLine1":"Please Select jpeg, png, pdf, doc, docx file Formats,",
        "noteLine2":"Max file size less than 5Mb, Max file quantity is 3.",
        "swalpo":"Purchase Order",
        "swalselect":"does not contain any selectable Items.",
        "swalLPQ":"Delivery qty should be less than Pending Qty",
        "swalgtz":"Delivery quantity should be greater than zero",
        "swaldngftpd":"Delivery note date should not be greater than posting date",
        "swaldn":"Please enter delivery note number",
        "swaltotqty":"Please enter total quantity",
        "swalamount":"Please enter total amount",
        "swalemail":"Enter a valid email address",
        "swalsdoc":"Please upload the supporting documents",
        "swalqty":"Please enter quantity",
        "swalc1":"Only 16 charecters are allowed",
        "swalc2":"Only 25 charecters are allowed",
        "swalc3":"Only 12 charecters are allowed",
        "swalformat":"Only JPG,PNG,PDF,DOC and DOCX files are allowed.",
        "swalsize":"File size cannot be greater than 5mb",
        "swaldiffile":"File is already uploaded, please upload different file",
        "swalmaxfile":"Maximum limit 3 files",
        "swaldec":"Three numbers only allowed after decimal point",
        "swalfdec":"Decimal point not allowed at first place",
        "swalneg":"Negative value are not allowed",
        "swalcp":"copy and paste not allowed",
        "swalPoEr":"Please select purchase order number"
      }
    }
    else if(lang == 'th'){
      this.language = {
        "head":"เพิ่มใบส่งสินค้า",
        "companyName":	"ชื่อบริษัท *",
        "plantName":	"รหัสโรงงาน/สำนักงาน *",
        "PONumber":	"เลขที่ใบสั่งซื้อ",
        "deliveryNoteDate" :	"วันที่บันทึกการจัดส่ง",
        "deliveryNoteNumber" :	"หมายเลขใบส่งสินค้า",
        "postingDate" :	"วันที่โพสต์",
        "totalQuantity":	"ปริมาณทั้งหมด",
        "totalAmount" :	"จำนวนเงินทั้งหมด",
        "headerText":	"ข้อความส่วนหัว",
        "deliveryPersonEmail":	"อีเมล์คนส่งของ",
        "deliveryPersonName":	"ชื่อผู้ส่งสินค้า",
        "attachments":"เอกสารแนบ",
        "vendorCode":"รหัสผู้ขาย *",
        "deliveryNoteDetails":	"รายละเอียดใบส่งสินค้า",
        "actions":	"การดำเนินการ",
        "deliveringQuantity":	"ส่งมอบจำนวน",
        "POItem"	:"รายการสั่งซื้อ",
        "materialNo"	:"วัสดุ ตัวเลข",
        "shortText":	'ข้อความสั้น',
        "POQTY":	"จำนวนที่สั่งซื้อ",
        "unit":	"หน่วย",
        "deliveryDate"	:"วันที่จัดส่ง",
        "receivedQTY"	:"จำนวนที่ทำรับแล้ว",
        "pendingQTY":	"รอดำเนินการ ปริมาณ",
        "unitPrice"	:"ราคาต่อหน่วย",
        "itemAmount":	"จำนวนรายการ",
        "invoicedAmount":	"จำนวน GR",
        "category":"หมวดหมู่",
        "storageLocation":"สถานที่จัดเก็บ",
        "deliveringQty":"ปริมาณการส่งมอบ",
        "subItemNo":"รายการย่อยหมายเลข",
        "bom":"บอม",
        "submit":"ส่ง",
        "cancel":"ยกเลิก",
        "note":"หมายเห",
        "noteLine1":"โปรดเลือกรูปแบบไฟล์ jpeg, png, pdf, doc, docx,",
        "noteLine2":"ขนาดไฟล์สูงสุดน้อยกว่า 5Mb ปริมาณไฟล์สูงสุดคือ 3.",
        "swalpo":"ใบสั่งซื้อ ",
        "swalselect":" ไม่มีรายการที่เลือกได้",
        "swalLPQ":"ปริมาณการจัดส่งควรน้อยกว่าปริมาณที่รอดำเนินการ",
        "swalgtz":"ปริมาณการจัดส่งควรมากกว่าศูนย์",
        "swaldngftpd":"วันที่บันทึกการจัดส่งไม่ควรเกินวันที่โพสต์",
        "swaldn":"กรุณากรอกเลขที่ใบส่งของ",
        "swaltotqty":"กรุณาใส่จำนวนทั้งหมด",
        "swalamount":"กรุณาใส่จำนวนเงินทั้งหมด",
        "swalemail":"ใส่อีเมล์ที่ถูกต้อง",
        "swalsdoc":"กรุณาอัพโหลดเอกสารประกอบการ",
        "swalqty":"กรุณากรอกจำนวน",
        "swalc1":"อนุญาตให้ใช้อักขระได้เพียง 16 ตัวเท่านั้น",
        "swalc2":"อนุญาตให้ใช้อักขระได้เพียง 25 ตัวเท่านั้น",
        "swalc3":"อนุญาตให้ใช้อักขระได้เพียง 12 ตัวเท่านั้น",
        "swalformat":"อนุญาตเฉพาะไฟล์ JPG, PNG, PDF, DOC และ DOCX",
        "swalsize":"ขนาดไฟล์ต้องไม่เกิน 5Mb",
        "swaldiffile":"ไฟล์ถูกอัพโหลดแล้ว กรุณาอัพโหลดไฟล์อื่น",
        "swalmaxfile":"จำกัดสูงสุด 3 ไฟล์",
        "swaldec":"อนุญาตให้ใช้ตัวเลขสามตัวหลังจุดทศนิยมเท่านั้น",
        "swalfdec":"ไม่อนุญาตให้ใช้จุดทศนิยมในตอนแรก",
        "swalneg":"ค่าลบไม่ได้รับอนุญาต",
        "swalcp":"ไม่อนุญาตให้คัดลอกและวาง",
        "swalPoEr":"กรุณาเลือกเลขที่ใบสั่งซื้อ"
      }
    }

    if (this.currentUser.roleID == 3 || this.currentUser.roleID == 7) {
      // if(this.cService.isRoleExists(this.currentUser.roleIds, verifyRole)){
      this.columnsToDisplay = [
        "action","delQty","totalAmt","unit","storageLocation","poNumber","poItem","materialNo","shortText","poQty","deliveryDate","receivedQty","pendingQty","unitPrice","poAmount","invoicedAmount",
      ];
      // ["poNumber", "poItem","poQty", "receivedQty","pendingQty", "unitPrice", "poAmount", "delQty", "totalAmt", "storageLocation", "materialNo","category", "shortText","action"]
    } else {
      this.columnsToDisplay = [
        "action","delQty","poNumber","poItem","materialNo","shortText","poQty","unit","deliveryDate","receivedQty","pendingQty","unitPrice","poAmount","invoicedAmount","totalAmt",
      ];
    }
    if (this.currentUser.roleID == 3 || this.currentUser.roleID == 7) {
      this.displayData = true;
    }
    this.date = new Date();
    this.addDeliveryNotes = this.fb.group({
      // poNumber: ["", [Validators.required]],
      // plantType: ["", [Validators.required]],
      // vendorType: ["", [Validators.required]],
      billOfLading: [""],
      dlDate: ["", [Validators.required]],
      dlqnty: ["", [Validators.required]],
      dlNum: ["", [Validators.required]],
      categoryType: ["", [Validators.required]],
      psDate: ["", [Validators.required]],
      hedText: [""],
      venName: ["", [Validators.required]],
      // prGroup: ["", [Validators.required]],
      postingDate: ["", [Validators.required]],
      totalQty: ["", [Validators.required]],
      header: ["", [Validators.required]],
      dlPerson: [""],
      dlNumber: ["", [Validators.required]],
      shipmentNo: [""],
      billOfLanding: ["", [Validators.required]],
      totalAmt: ["", [Validators.required]],
      category: ["", [Validators.required]],
      dlPersonEmail: [
        "",
        [
          Validators.email,
          Validators.pattern(this.emailPattern),
        ],
      ],
      delQtys: ["", [Validators.required]],
      productInformationMasters: this.fb.array([]),
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: "poId",
      textField: "poNumber",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    this.dropdownSettings1 = {
      singleSelection: true,
      idField: "id",
      textField: "nameOne",
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.dropdownSettings2 = {
      singleSelection: true,
      idField: "plantCode",
      textField: "plantName",
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    this.dropdownSettings4 = {
      singleSelection: true,
      idField: "companyCode",
      textField: "companyName",
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
   
    if (this.currentUser.roleID == 2) {
     await this.getCompanyMaster(this.currentUser.usertype);
    }else{
      await this.getVendorCodes();
    }

    // this.getPlantMaster("1200");
    // this.getPlantMaster();
    this.loadSupportingDocs();
  }
  listValue: any;
  datacache: any = [];
  loadSupportingDocs() {
    let data = localStorage.getItem("supportDocs");
    if (data == undefined) {
      localStorage.setItem("supportDocs", JSON.stringify([]));
      this.listValue = [];
    } else {
      this.listValue = JSON.parse(localStorage.getItem("supportDocs"));
    }
  }
  plantMaster: any = [];
  categoryMaster = [
    { categoryId: "001", categoryName: "Goods receive Mvt 101" },
    { categoryId: "002", categoryName: "Goods receive Mvt 103" },
    {
      category: "001-1",
      categoryName: "Release GR Blocked Stock (MIGO #001-1)",
    },
    { category: "005", categoryName: "Sub Contract GR (MIGO #005)" },
    { category: "008", categoryName: "GR of Scheduling Agreement (MIGO #008" },
  ];
  poLists: any = [];
  plantId: any;
  async getPlantMaster(com: any) {
    (await this.cService
      .getPlantMaster(com))
      .pipe(first())
      .subscribe(
        async (data) => {
          let plantData = [];
          if (data != null) {
            if (data.responseStatus.toUpperCase() == "SUCCESS") {
              for (let i of data.response) {
                let obj = {
                  plantCode: i.plantCode,
                  plantName: i.plantCode + "-" + i.nameEn,
                };
                plantData.push(obj);
              }
              this.plantMaster = plantData;

              this.showOverlay = false;
            } else {
              // this.showOverlay = false;
           
              if(data.responseCode == '401'){
                (await this.cService
                  .getPlantMaster(com))
                  .pipe(first())
                  .subscribe(
                    (data) => {
                      let plantData = [];
                      if (data != null) {
                        if (data.responseStatus.toUpperCase() == "SUCCESS") {
                          for (let i of data.response) {
                            let obj = {
                              plantCode: i.plantCode,
                              plantName: i.plantCode + "-" + i.nameEn,
                            };
                            plantData.push(obj);
                          }
                          this.plantMaster = plantData;
            
                          this.showOverlay = false;
                        } else {
                          this.showOverlay = false;
                          swal.fire(data.responseMessage, "", "error");
                          
                        }
                      }
                    },
                    () => {
                      this.showOverlay = false;
                    }
                  );
              }else{
                this.showOverlay = false;
                swal.fire(data.responseMessage, "", "error");
              }
            }
          }
        },
        () => {
          this.showOverlay = false;
        }
      );
  }
  companyMaster: any = [];
  async getCompanyMaster(vendor: any) {
    this.mySpinner = true;
    this.companyMaster = [];
    (await this.cService
      .getCompanyMaster(vendor))
      .pipe(first())
      .subscribe(
        async (data) => {
          let companyData = [];
          // if (data != null) {
          if (data.responseStatus.toUpperCase() == "SUCCESS") {
            for (let i of data.response) {
              let obj = {
                companyCode: i.companyCode,
                companyName: i.companyCode + "-" + i.name,
              };
              companyData.push(obj);
            }
            this.companyMaster = companyData;
            this.mySpinner = false;
          } else {
            
            if(data.responseCode == '401'){
              (await this.cService
                .getCompanyMaster(vendor))
                .pipe(first())
                .subscribe(
                  (data) => {
                    let companyData = [];
                    // if (data != null) {
                    if (data.responseStatus.toUpperCase() == "SUCCESS") {
                      for (let i of data.response) {
                        let obj = {
                          companyCode: i.companyCode,
                          companyName: i.companyCode + "-" + i.name,
                        };
                        companyData.push(obj);
                      }
                      this.companyMaster = companyData;          
                      this.mySpinner = false;
                    } else {
                      this.mySpinner = false;
                     
                      if(data.responseCode == '401'){
                        swal.fire(data.responseMessage, "", "error");
                      }
                    }
                    // }
                  },
                  (error) => {
                    this.mySpinner = false;
                  }
                );
            }else{
            this.mySpinner = false;
            swal.fire(data.responseMessage, "", "error");
            }
          }
          // }
        },
        (error) => {
          this.mySpinner = false;
        }
      );
  }
  showP: boolean = false;
  companyCodeValue: any;
  async changeCompany(event: any) {
    this.plantId = "";
    this.showP = true;
    this.plantMaster = [];
    this.plantId = '';
    this.allPOlist = [];
    this.dropdownList = [];
    this.selectedItems = [];
    this.deliveryDetails = [];
    this.companyCodeValue = event.companyCode;
    await this.getPlantMaster(this.companyCodeValue);
  }
  async changePlant(event: any) {
    this.plantId = "";
    this.showCN = true;
    this.allPOlist = [];
    this.dropdownList = [];
    this.selectedItems = [];
    this.deliveryDetails = [];
    // let plant = event.source.value;
    this.plantId = event.plantCode;
    

    await this.getPONumbers();
    if (this.currentUser.roleID == 3 || this.currentUser.roleID == 7) {
      await this.storageLocationList(this.plantId);
    }
  }

  async changeVendor(event: any) {
    this.show = true;
    let vendorCodex;
    for (let i of this.vendorList) {
      if (event.id == i.id) {
        vendorCodex = i.vendorCode;
      }
    }
    this.companyMaster = [];
    this.plantCode = "";
    this.plantId = "";
    this.companyCodeValue = "";
    this.plantMaster = [];
    this.selectedItems = [];
    this.allPOlist = [];
    this.dropdownList = [];
    this.deliveryDetails = [];
    this.displayData = false;
    this.vendorCode = event.id + "-" + vendorCodex;
   await this.getCompanyMaster(vendorCodex);
  }
  nameCode: any = [];
 async getVendorCodes() {
    this.mySpinner = true;
    (await this.aService
     .getVendorsCode())
      .pipe(first())
      .subscribe(
        (res) => {
          this.mySpinner = false;
          if (res.responseStatus.toUpperCase() == "SUCCESS") {
            this.vendorList = res.response;
            this.vendorList.forEach((i) => {
              let obj = {
                id: i.id,
                isActive: i.isActive,
                isBlocked: i.isBlocked,
                nameOne: i.vendorCode + "-" + i.nameOne,
                vendorCode: i.vendorCode,
              };
              this.nameCode.push(obj);
            });
            this.vendorList = this.nameCode;
          }
        },
        (error) => {
          this.mySpinner = false;
        }
      );
  }
  allPOlist: any = [];
  async getPONumbers() {
    this.dropdownList = [];
    this.deliveryDetails = [];
    this.selectedItems = [];
    this.mySpinner = true;
    let vendorId: any;
    if (this.currentUser.roleID == 3 || this.currentUser.roleID == 7) {
      vendorId = this.vendorCode.split("-")[0];
    } else {
      vendorId = this.currentUser.vendorId;
    }
    (await this.cService
      .getPONumbers(this.plantId, "G", vendorId, this.companyCodeValue))
      .pipe(first())
      .subscribe(
        (data) => {
          // this.mySpinner =false;
          if (data != null) {
            if (data.responseStatus.toUpperCase() == "SUCCESS") {
              this.allPOlist = data.response;
              this.dropdownList = data.response;
              this.mySpinner = false;
            } else {
              this.mySpinner = false;
              swal.fire(data.responseMessage, "", "error");
            }
          }
        },
        () => {
          this.mySpinner = false;
        }
      );
  }
  storageMaster = [];

  async storageLocationList(plantId) {
    this.storageMaster = [];
    this.mySpinner = true;
    (await this.cService
      .getstorageLocation(plantId))
      .pipe(first())
      .subscribe(
        (data) => {
          if (data != null) {
            if (data.responseStatus.toUpperCase() == "SUCCESS") {
              this.storageMaster = data.response;
              this.mySpinner = false;
            } else {
              this.mySpinner = false;
              swal.fire(data.responseMessage, "", "error");
            }
          }
        },
        () => {
          this.mySpinner = false;
        }
      );
  }

  get productInformationMasters() {
    return this.addDeliveryNotes.get("productInformationMasters") as FormArray;
  }
  goTo() {
    this.router.navigate(["delivery-notes"]);
  }

  changeQty(event: any) {
    if (event.target.value) {
    }
  }

  public onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.certEscolar = file;
      this.fileCount++;
    }
  }
  selectedValues = [];
  onItemSelect(item: any) {
    this.showPO = true;
    //  let a=document.querySelector<HTMLInputElement>(".dropDownClass").style.marginTop="50px";
    
    let regExp = "/^7[0-9].*$/";
    var regExp1 = "/^L[A-Z].*$/";
    let dropdownListStart7 = [];
    let dropdownListStartL = [];
    let dropdownListStartE = [];

    for (let i of this.dropdownList) {
      if (i.itemCategory == null) {
        dropdownListStart7.push(i);
      } else if (i.itemCategory == "L") {
        dropdownListStartL.push(i);
      } else {
        dropdownListStartE.push(i);
      }
    }
    for (let x of dropdownListStartL) {
      if (item.poId == x.poId) {
        this.dropdownList = dropdownListStartL;
        this.lCategory = true;
        // this.expandedElement;
      }
    }

    for (let x of dropdownListStart7) {
      if (item.poId == x.poId) {
        this.dropdownList = dropdownListStart7;
        this.lCategory = false;
      }
    }
    for (let x of dropdownListStartE) {
      if (item.poId == x.poId) {
        this.dropdownList = dropdownListStartE;
        this.lCategory = false;
      }
    }
    this.selectedValues.push(item);
    this.selectedItems.push(item.poId);
    this.getDeliveryNoteDetails();
  }

  onSelectAll(item: any) {
    this.showPO = true;
    this.selectedValues = [];
    for (let i = 0; i <= item.length - 1; i++) {
      this.selectedValues.push(item[i]);
      this.selectedItems.push(item[i]);
    }
    this.getDeliveryNoteDetails();
  }

  onItemDeSelect(item: any) {
    // this.showCN = false;
    const index = this.selectedValues.indexOf(item.poId);
    const index1 = this.selectedItems.indexOf(item.poId);
    let selected =[];
    for(let x of this.selectedValues){
      if(x.poId != item.poId){
        selected.push(x);
      }
    }

    // this.selectedValues.splice(index, 1);
    // this.selectedItems.splice(index1, 1);
    this.selectedValues = selected;
    
    if (this.selectedItems.length == 0) {
      this.dropdownList = this.allPOlist;
    }
    this.getDeliveryNoteDetails();
  }
  onItemDeSelectCN(item: any) {
    this.showCN = false;
  }
  onItemDeSelectCom(item: any) {
    this.showP = false;
  }
  onItemDeSelectVn(item: any) {
    this.show = false;
  }
  onItemDeSelectAll(item: any) {
    this.showPO = false;
    // const index = this.selectedValues.indexOf(item);
    this.selectedItems = [];
    this.selectedValues = [];
    this.dropdownList = this.allPOlist;
    this.getDeliveryNoteDetails();
  }
  dataSubSource: any = [];
  subCateg: boolean = false;
  subBool: boolean = false;
  newCount: any;
  async getDeliveryNoteDetails() {
    this.deliveryDetails = [];
    let selectedItem = [];
    let ponum = [];
    for (let i of this.selectedValues) {
      if(i.poId != null){
        selectedItem.push(i.poId);
      }
      
       ponum.push(i.poNumber);
    }
    // let POIDs= (this.selectedValues).toString();
    let request = {
      poIds: selectedItem,
      plantCode: this.plantId,
      "typeOfInvoice": "G"
    };
    this.mySpinner = true;
    (await this.cService
      .getDeliveryNotedDetailsPO(request))
      .pipe(first())
      .subscribe(
        (data) => {
          let tableData = [];
          if (data != null) {
            if (data.responseStatus.toUpperCase() == "SUCCESS") {
              let count = 0;
              this.subBool = false;
              this.newCount = 0;
              for (let i = 0; i <= data.response.length - 1; i++) {
                if (data.response[i].outStandingQuantity > 0) {
                  this.subBool = false;
                  this.newCount++;
                  data.response[i].uid = count;
                  tableData.push(data.response[i]);
                  count++;
                } else {
                  this.subBool = true;
                }
                // if(i.outStandingQuantity > 0){
                // tableData.push(i);
                //}
              }
              if (this.newCount == 0 && this.subBool == true) {
                swal.fire(`${this.language.swalpo} ` + ponum + ` ${this.language.swalselect}` ,"","warning");
              }
              this.subCateg = false;
              this.deliveryDetails = tableData;
              for (let i of this.deliveryDetails) {
                if (i.itemCategory != null) {
                  if (i.itemCategory == "L") {
                    if(this.currentUser.roleID == 3 || this.currentUser.roleID == 7 ){
                      this.subCateg = true;
                    }
                    
                    this.isActiveDiv = true;
                    let obj = {
                      materialNo: i.subContractMaterial,
                      shortText: i.shortText,
                      subItemNo: i.itemNo,
                      quantity: "0",
                    };
                    this.dataSubSource.push(obj);
                  } else {
                    this.subCateg = false;
                  }
                }
              }
              this.mySpinner = false;
            } else {
              this.mySpinner = false;
              // swal.fire(data.responseMessage);
            }
          }
        },
        (error) => {
          this.mySpinner = false;
        }
      );
  }

  onSubQty(event: any, element: any) {
    // this.keyPressNumbers(event);
    // if (parseFloat(element.outStandingQuantity) < parseFloat(element.quantity)) {
        // element.quantity = 0;
        this.onDelQtyChangeSub(event, element);
    // }
    
  }
  subContractData:any=[];
  onDelQtyChangeSub(event: any, element: any) {
    let etv = 0;
    let totalQty = 0;
    let totalAmt:any = 0;
    etv = event.target.value.replace(/,/g, "");
    if (parseFloat(element.outStandingQuantity) < etv) {

      swal.fire(`${this.language.swalLPQ}`, "" ,"warning");

      element.warehouseReceivedQuantity = 0;//Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      event.target.value = 0;//Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      element.totalAmt = 0;
      // element.warehouseReceivedQuantity = (0).toLocaleString('en',{minimumFractionDigits:3}),
      element.deliveryQty = (0).toLocaleString('en',{minimumFractionDigits:3});
      // this.addDeliveryNotes.controls.totalQty.setValue(0);
      // this.addDeliveryNotes.controls.totalAmt.setValue(0);
      let totalQty = 0;
      let totalAmt:any = 0;
      for (let i of this.deliveryDetails){
          if(i.warehouseReceivedQuantity != undefined){
            let x = i.warehouseReceivedQuantity;//.replace(/,/g, "");
            if(i.totalAmt != undefined){
              totalQty = totalQty + Number(x);
              totalAmt = totalAmt + i.totalAmt;
            }else{
              totalQty = totalQty + Number(x);
              totalAmt = totalAmt + i.amount;
            }
          }
      } 
      totalAmt = totalAmt.toFixed(2);
      totalAmt = Number(totalAmt);
      this.addDeliveryNotes.controls.totalQty.setValue(totalQty.toLocaleString('en',{minimumFractionDigits:3}));
      this.addDeliveryNotes.controls.totalAmt.setValue(totalAmt.toLocaleString('en',{minimumFractionDigits:2}));
      element.warehouseReceivedQuantity = Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      element.deliveryQty = Number(etv).toLocaleString('en',{minimumFractionDigits:3});

    } else {
      // let etv = event.target.value.replace(/,/g, "");
      element.totalAmt = etv * element.netPrice;
      element.warehouseReceivedQuantity = etv;//Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      element.deliveryQty = etv;//Number(etv).toLocaleString('en',{minimumFractionDigits:3});      
      for (let i of this.deliveryDetails) {
        
        if (i.warehouseReceivedQuantity != undefined) {
          let qty = i.warehouseReceivedQuantity.replace(/,/g, "");
          totalQty = totalQty + Number(qty);
          totalAmt = totalAmt + i.totalAmt;
        }
      }
      
    
      this.subContractData[element.purchaseOrderDetailsId] = {
        subItemNo: element.itemNo, 
        subItemMaterial:  element.subContractMaterial, 
        billOfMaterial:  element.billOfMaterial, 
        subItemShortText:  element.shortText, 
        subItemReceivedQty: element.quantity
      }
      
      totalAmt = totalAmt.toFixed(2);
      totalAmt = Number(totalAmt);
      this.addDeliveryNotes.controls.totalQty.setValue(totalQty.toLocaleString('en',{minimumFractionDigits:3}));
      this.addDeliveryNotes.controls.totalAmt.setValue(totalAmt.toLocaleString('en',{minimumFractionDigits:2}));
      element.warehouseReceivedQuantity = Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      element.deliveryQty = Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      
    }
    // this.onChangeAmtQty(totalQty,totalAmt);
  }
  onChangeAmtQty(totalQty,totalAmt){
    this.addDeliveryNotes.controls.totalQty.setValue(totalQty.toLocaleString('en',{minimumFractionDigits:3}));
    this.addDeliveryNotes.controls.totalAmt.setValue(totalAmt.toLocaleString('en',{minimumFractionDigits:2}));
  }
  onsave() {
  }

  onDropDownClose() {
  }
  supportDocs: any = [];
  delQty: any = [];
  vendorCode: any;
  plantCode: any;
  filterDeliveryNoteDetails: any = [];
  onSubmit() {
    let data1: any = [];
    let subData: any = [];
    let mainObj: {};
    let category;
    let storageLocation;
    let objx: any = [];
    // for (let i of this.deliveryDetails){
    //   if(i.warehouseReceivedQuantity <= 0){
    //     swal.fire("Delivery quantity should be greater than zero");
    //     return;
    //   }
    // }
    for (let i = 0; i < this.deliveryDetails.length; i++) {
      if (this.deliveryDetails[i].warehouseReceivedQuantity != "") {
        if (this.deliveryDetails[i].warehouseReceivedQuantity != null) {
          if (this.deliveryDetails[i].warehouseReceivedQuantity != undefined) {
            this.filterDeliveryNoteDetails.push(this.deliveryDetails[i]);
          } else {
            this.filterDeliveryNoteDetails.push(this.deliveryDetails);
          }
        }
      }
    }
    for (let i = 0; i < this.deliveryDetails.length; i++) {
      if (this.deliveryDetails[i].itemCategory == "L") {
        category = "005";
      } else if (this.deliveryDetails[i].itemCategory == null) {
        category = "002";
      } else {
        category = "008";
      }

      if (
        this.deliveryDetails[i].itemCategory != null ||
        this.deliveryDetails[i].itemCategory != undefined
      ) {
        if (this.deliveryDetails[i].itemCategory == "L") {
          // if(this.deliveryDetails[i].itemNo == this.deliveryDetails[i].poItem){
          let obj = {
            subItemNo: this.deliveryDetails[i].itemNo,
            subItemMaterial: this.deliveryDetails[i].subContractMaterial,
            billOfMaterial: this.deliveryDetails[i].billOfMaterial,
            subItemShortText: this.deliveryDetails[i].shortText,
            subItemReceivedQty: this.deliveryDetails[i].warehouseReceivedQuantity,
          };
          subData.push(obj);
          // }
        }
      }
      if (subData[i] == null || subData[i] == undefined) {
        objx = [];
      } else {
        objx.push(subData[i]);
      }
    }
    for (let i = 0; i < this.filterDeliveryNoteDetails.length; i++) {
      if (this.filterDeliveryNoteDetails[i].warehouseReceivedQuantity <= 0) {
        swal.fire(`${this.language.swalgtz}`, "", "warning");
        return;
      }
      
      if (this.currentUser.roleID == "3" || this.currentUser.roleID == 7) {
        let obj = [];

        if (this.deliveryDetails[i].itemCategory == "L") {
          // if(this.subContractData[this.filterDeliveryNoteDetails[i].purchaseOrderDetailsId] != null){
            obj.push(this.subContractData[this.filterDeliveryNoteDetails[i].purchaseOrderDetailsId]);
          // }else{
          //   obj = [];
          // }
            
        }else{
          obj = [];
        }
        
        mainObj = {
          poNumber: this.filterDeliveryNoteDetails[i].poNumber,
          poItem: this.filterDeliveryNoteDetails[i].poItem,
          // category fetch based on
          category: category,
          // materialG: this.deliveryDetails[i].materialG,
          // materialNo: this.deliveryDetails[i].materialNo,
          poId: this.filterDeliveryNoteDetails[i].id,
          podId: this.filterDeliveryNoteDetails[i].purchaseOrderDetailsId,
          storageLocation: this.filterDeliveryNoteDetails[i].storageLocation, //warehouse
          // shortText: this.deliveryDetails[i].shortText,
          // poQty: this.deliveryDetails[i].scheduledQuantity,
          grQuantity: this.filterDeliveryNoteDetails[i].grQuantity,
          // pendingQty: this.deliveryDetails[i].outStandingQuantity,
          // unitPrice: this.deliveryDetails[i].netPrice,
          // poAmount: this.deliveryDetails[i].grossValue,
          warehouseReceivedQuantity:this.filterDeliveryNoteDetails[i].warehouseReceivedQuantity,
          amount:Math.round(this.filterDeliveryNoteDetails[i].totalAmt * 100)/100,
          subContractSubItems: obj,
        };
        data1.push(mainObj);
      } else {
        let subC;
        if (this.deliveryDetails[i].itemCategory == "L") {
          subC = [
            {
              "subItemNo":this.filterDeliveryNoteDetails[i].itemNo,
              "subItemMaterial":this.filterDeliveryNoteDetails[i].subContractMaterial,
              "billOfMaterial": "", 
              "subItemShortText":this.filterDeliveryNoteDetails[i].shortText,
              "subItemReceivedQty": "0"
            }
            
          ]
        }else{
         subC = [];
        }
        mainObj = {
          poNumber: this.filterDeliveryNoteDetails[i].poNumber,
          poItem: this.filterDeliveryNoteDetails[i].poItem,
          // category fetch based on
          category: category,
          // materialG: this.deliveryDetails[i].materialG,
          // materialNo: this.deliveryDetails[i].materialNo,
          poId: this.filterDeliveryNoteDetails[i].id,
          podId: this.filterDeliveryNoteDetails[i].purchaseOrderDetailsId,
          storageLocation: this.filterDeliveryNoteDetails[i].storageLocation, //warehouse
          // shortText: this.deliveryDetails[i].shortText,
          // poQty: this.deliveryDetails[i].scheduledQuantity,
          grQuantity: this.filterDeliveryNoteDetails[i].grQuantity,
          // pendingQty: this.deliveryDetails[i].outStandingQuantity,
          // unitPrice: this.deliveryDetails[i].netPrice,
          // poAmount: this.deliveryDetails[i].grossValue,
          warehouseReceivedQuantity:this.filterDeliveryNoteDetails[i].warehouseReceivedQuantity,
          amount:Math.round(this.filterDeliveryNoteDetails[i].totalAmt * 100)/100,// 
          subContractSubItems: subC,
        };
        data1.push(mainObj);
  
      }
    }

    this.supportDocs = JSON.parse(localStorage.getItem("supportDocs"));
    // var test: any;

    let documents: any;
    let temp: any = [];
    if (this.supportDocs != null && this.supportDocs != undefined) {
      for (let i = 0; i <= this.supportDocs.length - 1; i++) {
        documents = {
          id: this.supportDocs[i].id,
        };
        temp.push(documents);
      }
    }
    let statusId;
    let vendorId;
    let sapVendorCode;
    if (this.currentUser.roleID == 3 || this.currentUser.roleID == 7) {
      // statusId = '2';// warehouse
      vendorId = this.vendorCode.split("-")[0];
      sapVendorCode = this.vendorCode.split("-")[1];
    } else {
      // statusId = '1';// vendor
      vendorId = this.currentUser.vendorId;
      sapVendorCode = this.currentUser.usertype;
    }

    let dndata = {
      deliveryNoteDate: this.datepipe.transform(this.addDeliveryNotes.value.dlDate,'yyyy-MM-dd'),
      plantCode: this.plantId,
      companyCode: this.companyCodeValue,
      postingDate: this.datepipe.transform(this.addDeliveryNotes.value.psDate,'yyyy-MM-dd'),
      quantity: this.addDeliveryNotes.value.totalQty,
      headerText: this.addDeliveryNotes.value.hedText,
      deliveryToPersonName: this.addDeliveryNotes.value.dlPerson,
      shipmentNumber: this.addDeliveryNotes.value.shipmentNo,
      totalAmount: this.addDeliveryNotes.value.totalAmt,

      // statusId: statusId,
      deliveryToPersonEmail: this.addDeliveryNotes.value.dlPersonEmail,
      // deliveryNoteRefNumVp: this.addDeliveryNotes.value.dlNum,
      vendorId: vendorId,
      billOfLading: this.addDeliveryNotes.value.billOfLading,
      // dnAddedByType: "V",
      // isApproved:"Y",
      sapVendorCode: sapVendorCode,
      deliveryNoteNumberByVendor: this.addDeliveryNotes.value.dlNum,
      deliveryNoteItemDetails: data1,
      deliveryNoteSupportDocs: temp,
    };
    this.filterDeliveryNoteDetails = [];

    let nameregex = /^[a-zA-Z ]*$/;
    let emailregex =
      /^([\w-]|(?<!\.)\.)+[a-zA-Z0-9]@[a-zA-Z0-9]([\w\-]+)((\.([a-zA-Z]){2,9})+)$/;

    if (
      this.selectedItems.length == 0
    ) {
      swal.fire(`${this.language.swalPoEr}`, "", "warning");
      return;
    }
    if (
      this.addDeliveryNotes.value.dlDate > this.addDeliveryNotes.value.psDate
    ) {
      swal.fire(`${this.language.swaldngftpd}`, "", "warning");
      return;
    } else if (
      this.addDeliveryNotes.value.dlNum == "" ||
      this.addDeliveryNotes.value.dlNum == undefined
    ) {
      swal.fire(`${this.language.swaldn}`, "", "error");
      return;
    }
    // else if (
    //   this.addDeliveryNotes.value.shipmentNo == "" ||
    //   this.addDeliveryNotes.value.shipmentNo == undefined
    // ) {

    //   swal.fire("Please enter shipment number");
    //   return;
    // }
    // else if (
    //   this.addDeliveryNotes.value.dlDate == "" ||
    //   this.addDeliveryNotes.value.dlDate == undefined
    // ) {
    //   swal.fire("Please enter delivery date");
    //   return;
    // }
    else if (
      this.addDeliveryNotes.value.totalQty == "" ||
      this.addDeliveryNotes.value.totalQty == undefined || this.addDeliveryNotes.value.totalQty == 0
    ) {
      swal.fire(`${this.language.swaltotqty}`, "", "warning");
      return;
    } else if (
      this.addDeliveryNotes.value.totalAmt == "" ||
      this.addDeliveryNotes.value.totalAmt == undefined
    ) {
      swal.fire(`${this.language.swalamount}`, "", "warning");
      return;
    } 
    // else if (
    //   this.addDeliveryNotes.value.dlPerson == "" ||
    //   this.addDeliveryNotes.value.dlPerson == undefined
    // ) {
    //   swal.fire("Please enter delivery person name", "", "warning");
    //   return;
    // } else if (
    //   this.addDeliveryNotes.value.dlPersonEmail == "" ||
    //   this.addDeliveryNotes.value.dlPersonEmail == undefined
    // ) {
    //   swal.fire("Please enter delivery person email", "", "warning");
    //   return;
    // }
    if(this.addDeliveryNotes.value.dlPersonEmail != "" && !emailregex.test(this.addDeliveryNotes.value.dlPersonEmail)){
        swal.fire(`${this.language.swalemail}`, "", "warning");
        return;
    } 

    //  else if (
    //   this.addDeliveryNotes.value.hedText == "" ||
    //   this.addDeliveryNotes.value.hedText == undefined
    // ) {
    //   swal.fire("Please enter header text", "", "warning");
    //   return;
    // } 
    if (this.supportDocs == "" || this.supportDocs == undefined) {
      swal.fire(`${this.language.swalsdoc}`, "", "error");
      return;
    }
    if(this.addDeliveryNotes.value.totalQty <= 0){
      swal.fire(`${this.language.swalqty}`, "", "warning");
      return;
    }
    this.addDeliveryNote(dndata);
  }
  DLNumMax(e) {
    if (e.target.value.length > 16) {
      swal.fire(`${this.language.swalc1}`, "", "error");

      return;
    }
  }
  header(e) {
    if (e.target.value.length > 25) {
      swal.fire(`${this.language.swalc2}`, "", "error");

      return;
    }
  }
  dlPerson(e) {
    if (e.target.value.length > 12) {
      swal.fire(`${this.language.swalc3}`, "", "error");

      return;
    }
  }
  async addDeliveryNote(row_obj) {
    this.mySpinner = true;

    (await this.cService
      .addDeliveryNote(row_obj))
      .pipe(first())
      .subscribe(
        (data) => {
          // if (data != null) {
          if (data.responseStatus.toUpperCase() == "SUCCESS") {
            this.mySpinner = false;
            // this.getDeliveryNoteList(null);
            swal.fire("VPDN# "+ data.response.deliveryNoteRefNumVp.substring(data.response.deliveryNoteRefNumVp.indexOf('_')+1), data.responseMessage, 'success');
            this.router.navigate(["delivery-notes"]);
          } else {
            this.mySpinner = false;
            swal.fire({
              icon: 'error',
              text: data.responseMessage
            })
            // swal.fire(data.responseMessage);
          }
          // }
          // this.showOverlay=false;
        },
        (error) => {
          this.mySpinner = false;
        }
      );
  }
  // documentName = false;
  async upload(event: any) {
    let formData = new FormData();
    formData.append("files", event.target.files[0]);
    let fileName = event.target.files[0].name;
    let sFileExtension = fileName
      .split(".")
      [fileName.split(".").length - 1].toLowerCase();
    if (
      !(
        sFileExtension === "png" ||
        sFileExtension === "doc" ||
        sFileExtension === "jpeg" ||
        sFileExtension === "pdf" ||
        sFileExtension === "docx"
      )
    ) {
      swal.fire(`${this.language.swalformat}`, "", "error");
    } else {
      if (event.target.files[0].size > 5000000) {
        swal.fire(`${this.language.swalsize}`, "", "error");
        this.myInputVariable.nativeElement.value = "";
        return;
      } else {
        this.supportDocs = JSON.parse(localStorage.getItem("supportDocs"));
        if (this.supportDocs != null && this.supportDocs != undefined) {
          for (let i = 0; i <= this.supportDocs.length - 1; i++) {
            if (
              this.supportDocs[i].documentName == event.target.files[0].name
            ) {
              swal.fire(
                `${this.language.swaldiffile}`, "", "error"
              );
              this.myInputVariable.nativeElement.value = "";
              return;
            }
          }
          if (this.supportDocs.length >= 3) {
            swal.fire(`${this.language.swalmaxfile}`, "", "error");
            this.myInputVariable.nativeElement.value = "";
            return;
          }
        }
        this.mySpinner = true;
        (await this.cService
          .upload(formData))
          .pipe(first())
          .subscribe(
            (res) => {
              if (res.responseStatus.toUpperCase() == "SUCCESS") {
                let test: any = res.response[0]; //["message"].uploads;

                this.listValue = [];

                this.listValue.push({
                  id: test.id,
                  documentType: test.documentType,
                  documentPath: test.documentPath,
                  documentName: test.documentName,
                  documentExtension: test.documentExtension,
                  documentSize: test.documentSize,
                });

                var last = this.listValue
                  .slice(-1)
                  .pop(); /**Extract Last value  **/

                var obj = last;
                this.datacache.push(obj); /**Push Data value  **/

                let supportDoc: any = [];

                supportDoc = JSON.parse(localStorage.getItem("supportDocs"));
                let arr: any = [];
                if (supportDoc == null) {
                  localStorage.setItem(
                    "supportDocs",
                    JSON.stringify(this.listValue)
                  );
                } else {
                  for (let i = 0; i <= this.listValue.length - 1; i++) {
                    supportDoc.push(this.listValue[i]);
                  }
                  var result = supportDoc.reduce((unique, o) => {
                    if (!unique.some((obj) => obj.name === o.name)) {
                      unique.push(o);
                    }
                    return unique;
                  }, []);
                  //let docs = this.listValue.concat(arr);
                  localStorage.setItem(
                    "supportDocs",
                    JSON.stringify(supportDoc)
                  );
                }
                this.mySpinner = false;
                this.listValue = JSON.parse(
                  localStorage.getItem("supportDocs")
                );
              } else {
                this.mySpinner = false;
                swal.fire(res.responseMessage, "", "error");
              }
            },
            (error) => {
              this.mySpinner = false;
            }
          );
      }
    }
  }
  categorySelected: any;
  onCategoryChange(event: any) {
    this.categorySelected = event.source.value;
  }
  onStorageChange(event: any, element: any) {
    element.storageLocation = event.source.value;
    if (element.id == this.deliveryDetails.id) {
      this.deliveryDetails.storageLocation = event.source.value;
    } else {
      return null;
    }
  }
  onDelQtyChange(event: any, element: any) {
    let etv=0;
    etv = event.target.value.replace(/,/g, "");
    let totalQty = 0;
    let totalAmt:any = 0;
    if (parseFloat(element.outStandingQuantity) < etv) {
      event.target.value = 0;
      element.totalAmt = 0;
      element.warehouseReceivedQuantity = 0;
      let totalQty:number = 0;
      let totalAmt:number = 0;
     
      for (let i = 0; i < this.deliveryDetails.length; i++) {
        if(this.deliveryDetails[i].warehouseReceivedQuantity == undefined){
          totalQty = totalQty + 0;
          totalAmt = totalAmt + 0;
        }else{
          totalQty = totalQty + parseFloat(this.deliveryDetails[i].warehouseReceivedQuantity);
          totalAmt = totalAmt + (parseFloat(this.deliveryDetails[i].warehouseReceivedQuantity) * parseFloat(element.netPrice));
        }
        
        
      }
      swal.fire(`${this.language.swalLPQ}`, "", "warning");
    } else {
      // etv = (event.target.value).replace(/,/g, "");
      element.totalAmt = Number(etv) * element.netPrice;
      element.warehouseReceivedQuantity =etv; //Number(etv).toLocaleString('en',{minimumFractionDigits:3});
      
      for (let i of this.deliveryDetails) {
        
        if (i.warehouseReceivedQuantity != undefined) {
          let qty = i.warehouseReceivedQuantity.replace(/,/g, "");
          totalQty = totalQty + Number(qty);
          totalAmt = totalAmt + i.totalAmt;
          //totalAmt=1234566;
        }
      }
      
    }
    totalAmt = totalAmt.toFixed(2);
    totalAmt = Number(totalAmt);
    this.onDelQtyChangeSub1(event, element, totalQty,totalAmt, etv);
  }
  onDelQtyChangeSub1(event:any, element:any,totalQty:any,totalAmt:any,etv:any){
    if(event.target.value != NaN){
      element.quantity = Number(event.target.value).toLocaleString('en',{minimumFractionDigits:3});
    }else{
      element.quantity = (0).toFixed(3);
    }
    element.warehouseReceivedQuantity = Number(etv).toLocaleString('en',{minimumFractionDigits:3});
    this.addDeliveryNotes.controls.totalQty.setValue(totalQty.toLocaleString('en', {
      minimumFractionDigits: 3
  }));
    this.addDeliveryNotes.controls.totalAmt.setValue(totalAmt.toLocaleString('en', {
      minimumFractionDigits: 2
  }));
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;    
    // Only Numbers 0-9    
    if ((charCode < 48 || charCode > 57 ) && (charCode != 46)) {    
    event.preventDefault();    
    return false;    
    } else {    
    return true;    
    }    
    }

  newDocuments: any = [];
  deleteItem(id) {
    this.listValue = JSON.parse(localStorage.getItem("supportDocs"));
    for (let i = 0; i <= this.listValue.length - 1; i++) {
      if (id == this.listValue[i].id) {
        this.myInputVariable.nativeElement.value = "";
      } else {
        this.newDocuments.push(this.listValue[i]);
      }
    }

    localStorage.setItem("supportDocs", JSON.stringify(this.newDocuments));
    this.getDocus();
  }
  getDocus() {
    this.newDocuments = [];
    this.listValue = JSON.parse(localStorage.getItem("supportDocs"));
  }
  onPoInfo(poNumber) {
    this.router.navigate(["delivery-notes/po-info", poNumber, "p", ""]);
  }
  async downLoad(id: any) {
    this.mySpinner = true;
    (await this.cService
      .download(id))
      .pipe(first())
      .subscribe(
        (res) => {
          this.mySpinner = false;
        },
        (error) => {
          this.mySpinner = false;
        }
      );
  }
  removeItem(id) {
    let newData: any = [];
    for (let i of this.deliveryDetails) {
      if (i.purchaseOrderDetailsId == id) {
      } else {
        newData.push(i);
      }
    }
    this.deliveryDetails = newData;
    let totalQty = 0;
    let totalAmt = 0;
    for (let i of this.deliveryDetails){
        if(i.warehouseReceivedQuantity != undefined){
          let x = i.warehouseReceivedQuantity.replace(/,/g, "");
          if(i.totalAmt != undefined){
            totalQty = totalQty + Number(x);
            totalAmt = totalAmt + i.totalAmt;
          }else{
            totalQty = totalQty + Number(x);
            totalAmt = totalAmt + i.amount;
          }
        }
      }
      
  this.addDeliveryNotes.controls.totalQty.setValue(totalQty.toLocaleString('en',{minimumFractionDigits:3}));
  this.addDeliveryNotes.controls.totalAmt.setValue(totalAmt.toLocaleString('en',{minimumFractionDigits:2}));    
  }
  toggle() {
    this.lCategory = false;
  }

  allowTwodigit(event: any) {
    if (event.keyCode === 46 && event.target.value.split('.').length === 2) {
      return false;      
      }
    if (
      event.target.value.indexOf(".") > -1 &&
      event.target.value.split(".")[1].length > 2
    ) {
      // alert('Two numbers only allowed after decimal point');
      event.preventDefault();
      swal.fire(`${this.language.swaldec}`, "", "warning");
      return;
    }
  }
  // restrictZero (event:any){
  //   if (event.target.value  <= 0){
  //     swal.fire("Delivery quantity should be greater than zero");
  //   }
  // }
  notAllow(event: any) {
    if (event.target.value.length == 0 && event.which == 46) {
      event.preventDefault();
      swal.fire(`${this.language.swalfdec}`, "", "warning");
      return;
    }
  }
  Space(event: any) {
    if (event.key === " " && event.target.selectionStart === 0) {
      event.preventDefault();
    }
  }
  keypressalpha(event: any) {
    var inp = String.fromCharCode(event.keyCode);
    if (/^[a-zA-Z ]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  notAllowHyphen(e){
    if (e.which == 45) {
      e.preventDefault();
      swal.fire(`${this.language.swalneg}`, "", "warning");
      return;
    }
  }

  blurFunction(event,element){
    let targetV:any;
    targetV = (event.target.value).replace(/,/g, "");
    targetV = Number(targetV);
    event.target.value = (targetV).toLocaleString('en', { minimumFractionDigits: 3 });
    this.onDelQtyChangeSub(event, element);
  }
  verifyRole = [1,2,3,4,5,6,7,8];
  isRoleExists(roleIds:any[]){
    var isExist:Boolean = false;
    for( let role of roleIds ){
      for(let i  of this.verifyRole){
        if(role == i ){
          isExist = true;
          // return true;
        }else{
          isExist = false;
        }
      }

    }
    return isExist;
}
}
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];
